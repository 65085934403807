import { Box, SxProps, Tab, Tabs, TypographyStyle } from "@mui/material";
import { TabItemsProp } from "./tabSwitchUI";

const EnclosedTabSwitchUI = ({
  tabItems,
  tab,
  setTab,
  activeColor,
  textOverRideStyles,
  viewOverRideStyles,
}: {
  tabItems: TabItemsProp;
  tab: number;
  activeColor: string;
  setTab: Function;
  textOverRideStyles?: TypographyStyle;
  viewOverRideStyles?: SxProps;
}) => {
  // handler
  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };
  return (
    <Box sx={{ ...viewOverRideStyles }}>
      <Tabs value={tab} onChange={handleChange}>
        {tabItems.map(({ label, icon }) => (
          <Tab
            key={label}
            label={label}
            icon={<>{icon}</>}
            sx={{
              ...textOverRideStyles,
              flexGrow: 1,
              maxWidth: "50%",
              "&.Mui-selected": {
                color: `${activeColor} !important`,
              },
            }}
            disableRipple
          />
        ))}
      </Tabs>
    </Box>
  );
};

export default EnclosedTabSwitchUI;
