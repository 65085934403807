import { Box, Button, FormLabel } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import RHFTextField, { RHFTextFieldProps } from "./rhfTextField";

const FixedLabelRhfLocationField = ({
  name,
  helperText,
  type,
  ...other
}: RHFTextFieldProps) => {
  // form ctx
  const { setValue, setError } = useFormContext();

  // state
  const [gettingLocation, setgettingLocation] = useState(false);

  // handler
  const getLocationHandler = () => {
    if (navigator.geolocation) {
      setgettingLocation(true);
      setTimeout(() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const gps_lat = position.coords.latitude;
            const gps_lon = position.coords.longitude;
            console.log(`${gps_lat}, ${gps_lon}`, "here locations", position);
            setValue(name, `${gps_lat},${gps_lon}`, {
              shouldValidate: true,
              shouldDirty: true,
            });
            setgettingLocation(false);
          },
          (error) => {
            setError(name, { message: error?.message });
            setgettingLocation(false);
          },
          { enableHighAccuracy: true, timeout: 50000 }
        );
      }, 1000);
    }
  };

  return (
    <>
      {/* label UI */}
      <FormLabel
        required={other.required}
        sx={{
          "& .MuiFormLabel-asterisk": {
            // adds style to *
            color: "#DC3545",
            fontSize: "18px",
          },
          // adds style to label
          fontSize: "10px",
          margin: 0,
          color: "#b0b7cd!important",
          textTransform: "uppercase",
        }}
      >
        {other.label}
      </FormLabel>
      {/* field UI */}
      <Box
        sx={{
          "& .MuiFormHelperText-root": {
            fontSize: "12px!important",
          },
        }}
      >
        <RHFTextField
          disabled
          name={name}
          type={type}
          helperText={helperText}
          InputProps={{
            endAdornment: (
              <Button
                variant="contained"
                sx={{
                  display: "flex",
                  width: "150px",
                  color: "#000",
                  mb: "3px",
                  p: 0,
                  background: (theme) => theme.palette.text.primary,
                  ":hover": {
                    background: (theme) => theme.palette.text.primary,
                  },
                }}
                onClick={getLocationHandler}
                disabled={gettingLocation}
              >
                {gettingLocation ? "Locating" : "Locate Me"}
              </Button>
            ),
          }}
          // removes label from other obj
          {...{ ...other, label: "" }}
        />
      </Box>
    </>
  );
};

export default FixedLabelRhfLocationField;
