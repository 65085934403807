import { dd_value } from "../../../../../actions/common_actions";
import useGetCountryListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetCountryListViaQuery";
import useGetCountyListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetCountyListViaQuery";
import useGetDistrictListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetDistrictListViaQuery";
import useGetLocationViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetLocationViaQuery";
import useGetRegionsListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetRegionsListViaQuery";
// import useGetTeritoryListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetTeritoryListViaQuery";
import BorrowerCommonAddressEditUIUGA from "../BorrowerCommonAddressEditUIUga";

/**
 *
 * @param param0 title the header of the view, prop to children
 * @param param1 addressObj the address record, prop to children
 * @param param2 entity used to check the user privileage to show the edit btn, prop to children
 * @param param3 custId need to send for the update api, prop to children
 * @param param4 addressViewFor the current res address and biz address have the location pick option, to show or hide the location field this field is used, prop to children
 * @returns form UI for the address record
 */
const BorrowerCommonAddressFormInitValGetContainerForUga = ({
  title,
  addressObj,
  entity,
  custId,
  addressViewFor,
}: {
  title: string;
  addressObj: Record<string, string>;
  entity: string;
  custId: string;
  addressViewFor: "CUR_RES_ADD" | "PER_ADD" | "BIZ_ADD";
}) => {
  // query hook
  const {
    countryList,
    isFetched: isClFetched,
    isError: isClError,
  } = useGetCountryListViaQuery();
  const {
    regionList,
    isFetched: isRlFetched,
    isError: isRlError,
  } = useGetRegionsListViaQuery();
  const {
    districtList,
    isFetched: isDlFetched,
    isError: isDlError,
  } = useGetDistrictListViaQuery({
    parent_data_code: addressObj?.region,
  }); // district depends on region
  const {
    countyList,
    isFetched: isCnlFetched,
    isError: isCnlError,
  } = useGetCountyListViaQuery({
    parent_data_code: addressObj?.district,
  }); // county depends on district
  // const {
  //   territoryList,
  //   isFetched: isTlFetched,
  //   isError: isTlError,
  // } = useGetTeritoryListViaQuery();
  const {
    locationList,
    isFetched: isLlFetched,
    isError: isLlError,
  } = useGetLocationViaQuery();

  // for populate the mui fields we need {id: , name: } structure, but from api we are getting the id only for the res. fields, so that we are getting the obj from the dropdown cached list, *this query doesn't trigger the api call twice if we are calling the same api for autocomplete inside the form
  const countryVal = countryList?.data?.list?.find(
    (d: any) => d.id === addressObj?.country_code
  );
  const regionVal = regionList?.data?.list?.find(
    (d: any) => d.data_code === addressObj?.region
  );
  const districtVal = districtList?.data?.list?.find(
    (d: any) => d.data_code === addressObj?.district
  );
  const countyVal = countyList?.data?.list?.find(
    (d: any) => d.data_code === addressObj?.county
  );
  // const territoryVal = territoryList?.data?.list?.find(
  //   (d: any) => d.data_code === addressObj?.territory
  // );
  const locationVal = locationList?.data?.list?.find(
    (d: any) => d.data_code === addressObj?.location
  );

  const isAllDataFetchedOrError =
    (isClFetched || isClError) &&
    (isRlFetched || isRlError) &&
    (isDlFetched || isDlError) &&
    (isCnlFetched || isCnlError) &&
    // (isTlFetched || isTlError) &&
    (isLlFetched || isLlError);

  // used for unnecessory rendering when the data is not available
  if (!isAllDataFetchedOrError) return <></>;

  return (
    <BorrowerCommonAddressEditUIUGA
      title={title}
      addressId={addressObj?.id}
      entity={entity}
      custId={custId}
      addressViewFor={addressViewFor}
      initialVal={{
        countryVal: {
          name: countryVal?.name ?? "Choose",
          id: countryVal?.id ?? "choose",
        },
        regionVal: {
          name: regionVal?.data_value ?? "Choose",
          id: regionVal?.data_code ?? "choose",
        },
        districtVal: {
          name: districtVal?.data_value ?? "Choose",
          id: districtVal?.data_code ?? "choose",
        },
        countyVal: {
          name: countyVal?.data_value ?? "Choose",
          id: countyVal?.data_code ?? "choose",
        },
        // territoryVal: {
        //   name: territoryVal?.data_value ?? "Choose",
        //   id: territoryVal?.data_code ?? "choose",
        // },
        locationVal: {
          name: locationVal?.data_value ?? "Choose",
          id: locationVal?.data_code ?? "choose",
        },
        subCounty: addressObj?.sub_county ?? "",
        parish: addressObj?.parish ?? "",
        village: addressObj?.village ?? "",
        landMark: addressObj?.landmark ?? "",
        gps: addressObj?.gps ?? "",
        verificationMethod:
          (dd_value(addressObj?.verification_method) as string) ?? "",
      }}
    />
  );
};

export default BorrowerCommonAddressFormInitValGetContainerForUga;
