import React from "react";
import { Config, get } from "../../../helpers/storage_helper";
import { Box, useTheme } from "@mui/material";
import AddressKycCommon from "../../lead/component/AddressKycCommon";
import { TbBackground } from "react-icons/tb";
import { dd_value } from "../../../actions/common_actions";
import { Link } from "react-router-dom";

type FieldProps = {
  value: string;
  type: string;
  verify: boolean;
  modified: boolean;
};

type AddressResProps = {
  district: FieldProps;
  country: FieldProps;
  sub_country: FieldProps;
  parish: FieldProps;
  village: FieldProps;
  location: FieldProps;
  landMark: FieldProps;
  kyc_addr_verf:string,
  is_cust_id ?: string
  match_biz_cur_per ?:boolean
  // region: FieldProps;
};

type OwnerAddress = {
  biz_address: AddressResProps;
  owner_person: OwnerPersonAddr;
  nat_id_addr: AddressResProps;
  // region: FieldProps;
};

type OwnerPersonAddr = {
  permanent_res_addr: AddressResProps;
  owner_address: AddressResProps;
  // region: FieldProps;
};

const BusinessAddress = ({ address }: { address: OwnerAddress }) => {
  const theme = useTheme();
  const address_types = [
    { label: "Business Address", addr: address.biz_address,
      is_bg: address.biz_address?.match_biz_cur_per ?? false
     },
    {
      label: "Current Residential Address",
      ...( address?.owner_person.owner_address?.kyc_addr_verf && { sub_title: (
        <span>
          Verified by{" "}
          {address.owner_person.owner_address ? (
            address.owner_person.owner_address?.is_cust_id ? (
              <Link to={`/borrower/indiv/view/${address.owner_person.owner_address.kyc_addr_verf}`}  target="_blank" style={{ textDecoration: "underline" }}>
                {address.owner_person.owner_address.kyc_addr_verf}
              </Link>
            ) : (
              <span>
                {dd_value(address.owner_person.owner_address.kyc_addr_verf)}
              </span>
            )
          ) : (
            "-"
          )}
        </span>
      )}),
      addr: address.owner_person.owner_address,
      is_bg: address.owner_person.owner_address?.match_biz_cur_per ?? false ,
    },
    { label: "National ID Address", addr: address.nat_id_addr,
       is_bg: false },
    {
      label: "Permanent Address",
      addr: address.owner_person.permanent_res_addr,
      is_bg:  address.owner_person.permanent_res_addr?.match_biz_cur_per ?? false ,
    },
  ];
  console.log("address", address.owner_person.owner_address);
  return (
    <Box>
      <div className="floatAdvanceperiodHeaderBox">
        <h2 className={"floatAdvanceperiodHeader padL_15 no-margin"}>
          Business Address
        </h2>
      </div>
      <Box className="row mx-0 my-3" display={"flex"} justifyContent={"start"}>
        {address_types.map((item, idx) => {
          return (
            <>
              {item.addr && Object.keys(item.addr).length > 0 && (
                <Box
                  className="col-md-3 col-lg-3 col-sm-12 biz_address_fields"
                  key={idx}
                  id={item.label}
                >
                  <AddressKycCommon
                    data={item.addr ?? {}}
                    usedFor="customer_profile"
                    title={item.label}
                    sub_title={item.sub_title ?? ""}
                    HeaderoverRideStyles={{
                      textAlign: "center",
                      background: ` ${
                        item.is_bg
                          ? "#2fb8d8"
                          : // @ts-ignore
                            theme.palette.custom_colors.grey
                      }`,
                      color: theme.palette.text.primary,
                      fontWeight: "bold",
                      p: 1,
                    }}
                  />
                </Box>
              )}
            </>
          );
        })}
      </Box>
    </Box>
  );
};

export default BusinessAddress;
