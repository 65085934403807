import { useQuery } from "@tanstack/react-query";
import { getNameList } from "../../../../actions/common_actions";
import { common_req } from "../../../../helpers/common_helper";
import { RhfOptionType } from "../../../../type";

type RMsGetResProps = { data: RhfOptionType[] };

/**
 * 
 * @returns rms list and it's loading and error state
 */
const useGetRmsListViaQuery = () => {
  // const
  const reqObj = common_req({} as any);

  // query
  const {
    data: rmSList,
    isError,
    isLoading,
  } = useQuery<RMsGetResProps>({
    queryKey: ["list_of_rms"],
    queryFn: () =>
      getNameList(
        "rel_mgr",
        {
          ...reqObj,
          associated_with: "flow",
          status: "enabled",
        },
        "kyc"
      ),
    staleTime: 1000 * 60 * 5,
  });
  return { rmSList, isError, isLoading };
};

export default useGetRmsListViaQuery;
