import useGetCommuneListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetCommuneListViaQuery";
import useGetCountryListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetCountryListViaQuery";
import useGetDistrictListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetDistrictListViaQuery";
import useGetRegionsListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetRegionsListViaQuery";
import BorrowerCommonAddressEditUIMadagascar from "../BorrowerCommonAddressEditUIMadagascar";

/**
 *
 * @param param0 title the header of the view, prop to children
 * @param param1 addressObj the address record, prop to children
 * @param param2 entity used to check the user privileage to show the edit btn, prop to children
 * @param param3 custId need to send for the update api, prop to children
 * @param param4 addressViewFor the current res address and biz address have the location pick option, to show or hide the location field this field is used, prop to children
 * @returns form UI for the address record
 */
const BorrowerCommonAddressFormInitValGetContainerForMadagascar = ({
  title,
  addressObj,
  entity,
  custId,
  addressViewFor,
}: {
  title: string;
  addressObj: Record<string, string>;
  entity: string;
  custId: string;
  addressViewFor: "CUR_RES_ADD" | "PER_ADD" | "BIZ_ADD";
}) => {
  console.log(addressObj, "addressObj");
  // query hook
  const {
    countryList,
    isFetched: isClFetched,
    isError: isClError,
  } = useGetCountryListViaQuery();
  const {
    regionList,
    isFetched: isRlFetched,
    isError: isRlError,
  } = useGetRegionsListViaQuery();
  const {
    communeList,
    isFetched: isCnlFetched,
    isError: isCnlError,
  } = useGetCommuneListViaQuery({
    parent_data_code: addressObj?.district,
  });
  const {
    districtList,
    isFetched: isDlFetched,
    isError: isDlError,
  } = useGetDistrictListViaQuery({
    parent_data_code: addressObj?.region,
  }); // district depends on region

  // for populate the mui fields we need {id: , name: } structure, but from api we are getting the id only for the res. fields, so that we are getting the obj from the dropdown cached list, *this query doesn't trigger the api call twice if we are calling the same api for autocomplete inside the form
  const countryVal = countryList?.data?.list?.find(
    (d: any) => d.id === addressObj?.country_code
  );
  const regionVal = regionList?.data?.list?.find(
    (d: any) => d.data_code === addressObj?.region
  );
  const districtVal = districtList?.data?.list?.find(
    (d: any) => d.data_code === addressObj?.district
  );
  const communeVal = communeList?.data?.list?.find(
    (d: any) => d.data_code === addressObj?.commune
  );

  const isAllDataFetchedOrError =
    (isClFetched || isClError) &&
    (isRlFetched || isRlError) &&
    (isCnlFetched || isCnlError) &&
    (isDlFetched || isDlError);

  // used for unnecessory rendering when the data is not available
  if (!isAllDataFetchedOrError) return <></>;

  return (
    <BorrowerCommonAddressEditUIMadagascar
      title={title}
      addressId={addressObj?.id}
      entity={entity}
      custId={custId}
      addressViewFor={addressViewFor}
      initialVal={{
        countryVal: {
          name: countryVal?.name ?? "Choose",
          id: countryVal?.id ?? "choose",
        },
        regionVal: {
          name: regionVal?.data_value ?? "Choose",
          id: regionVal?.data_code ?? "choose",
        },
        communeVal: {
          name: communeVal?.data_value ?? "Choose",
          id: communeVal?.data_code ?? "choose",
        },
        districtVal: {
          name: districtVal?.data_value ?? "Choose",
          id: districtVal?.data_code ?? "choose",
        },
        village: addressObj?.village ?? "",
        gps: addressObj?.gps ?? "",
      }}
    />
  );
};

export default BorrowerCommonAddressFormInitValGetContainerForMadagascar;
