import { useQuery } from "@tanstack/react-query";
import { getListByUrl } from "../../../../actions/common_actions";
import { common_req } from "../../../../helpers/common_helper";
import { RhfOptionType } from "../../../../type";

type AudtiorsGetResProps = { data: { list: RhfOptionType[] } };

/**
 * 
 * @returns auditors list and it's loading and error state
 */
const useGetAuditorsListViaQuery = (roleCodes: string[]) => {
  // const
  const reqObj = common_req({} as any);
  // query
  const {
    data: auditorsList,
    isError,
    isLoading,
  } = useQuery<AudtiorsGetResProps>({
    queryKey: ["list_of_auditors", ...roleCodes],
    queryFn: () =>
      getListByUrl("common", "priv_users", {
        ...reqObj,
        priv_code: "",
        status: "enabled",
        role_codes: roleCodes,
      }),
    staleTime: 1000 * 60 * 5,
  });
  return {
    isLoading,
    auditorsList,
    isError,
  };
};

export default useGetAuditorsListViaQuery;
