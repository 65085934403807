import { useQuery } from "@tanstack/react-query";
import { getReport } from "../../../actions/report_actions";
import { common_req } from "../../../helpers/common_helper";
import moment, { Moment } from "moment";
import EnclosedTabSwitchUI from "../../CommonComponent/enclosedTabSwitchUI";
import { useState } from "react";
import ReactApexChart from "react-apexcharts";
import useGetAreaChart from "../../../commonHooks/chart/useGetAreaChart";
import { ERROR_INFO } from "../../../consts";
import { Box } from "@mui/material";

type Props = {
  chartType: "contributed_by_fa_upgrade" | "fa_upgrades_vs_new_customers";
  country: string;
  startDate: Moment;
  endDate: Moment;
  lender: string;
  activeCurrency: string;
  currencyForex: Record<
    string,
    { currency_code: string; forex_rates: Record<string, number> }
  >;
};

type PortFolioGetResProps = {
  data: PortFolioData;
};

type PortFolioData = {
  month: string[];
  count: number[];
  percentage: number[];
  amount: number[];
};

const PortFolioIncreaseChart = ({
  chartType,
  country,
  startDate,
  endDate,
  lender,
  activeCurrency,
  currencyForex,
}: Props) => {
  // cb
  const getIsNeedToCurrencyConversion = () => {
    if (country === "*") return false;
    else if (activeCurrency === currencyForex[country]?.currency_code)
      return false;
    else return true;
  };

  // const
  const sD = startDate.format("YYYY-MM-DD");
  const eD = moment(endDate).isSame(moment(), "month")
    ? moment().format("YYYY-MM-DD")
    : moment(endDate).format("YYYY-MM-DD");
  const req = common_req({} as any);
  req.report_type = "portfolio_increase_fa_upgrade";
  req.start_date = sD;
  req.end_date = eD;
  req.country_code = country;
  if (lender) {
    req.lender = lender;
  }
  const isNeedToCurrencyConversion = getIsNeedToCurrencyConversion(); // checking for the need of currency conversion
  let multiplyValue = 1; // by default set the multiplyVlaue as 1
  if (isNeedToCurrencyConversion) {
    multiplyValue = currencyForex[country]?.forex_rates[activeCurrency]; // depends on the currency change the forex rate is taken
  }

  // query
  const { data, isLoading, isError } = useQuery<PortFolioGetResProps>({
    queryKey: [
      "portfolio_increase_fa_upgrade",
      { startDate: sD, endDate: eD, country: country, lender },
    ],
    queryFn: () => getReport(req),
    staleTime: 1000 * 3600 * 24, // 24hrs cache
  });

  // state
  const [activeTab, setActiveTab] = useState(0); // used for amount or count switch in contributed_by_fa_upgrade chart type in fa_upgrades_vs_new_customers chart type there is only one tab called percent

  if (isLoading)
    // loading UI
    return (
      <div style={{ marginTop: "30px" }}>
        <p className="text-light mt-3 text-center">Loading...</p>
        <div className="product_takeup_loader"></div>
      </div>
    );

  if (isError)
    // Error UI
    return (
      <div style={{ marginTop: "30px" }}>
        <p className="text-light mt-3 text-center">{ERROR_INFO}</p>
      </div>
    );

  return (
    <Box sx={{ px: 4 }}>
      <EnclosedTabSwitchUI // switch UI
        viewOverRideStyles={{
          display: "flex",
          justifyContent: "end",
          height: "35px",
          mb: 2,
          "& button": { minHeight: "auto" },
          "& .MuiTabs-indicator": {
            height: "100% !important",
            backgroundColor: "#3149a1",
            zIndex: -1,
          },
          "& .MuiTabs-root": {
            minHeight: "35px",
            border: "solid 2px #3149a1",
          },
        }}
        tab={activeTab}
        setTab={setActiveTab}
        tabItems={
          // conditionally set the tab options depends on chart type
          chartType === "contributed_by_fa_upgrade"
            ? [
                { label: "AMOUNT", icon: <></> },
                { label: "COUNT", icon: <></> },
              ]
            : [{ label: "PERCENT", icon: <></> }]
        }
        activeColor="#fff"
      />
      <AreaChartUI
        country={country}
        data={data?.data!}
        activeTab={activeTab}
        activeCurrency={activeCurrency}
        chartType={chartType}
        multiplyValue={multiplyValue}
      />
    </Box>
  );
};

export default PortFolioIncreaseChart;

type ChartDataProps = {
  country: string;
  activeCurrency: string;
  data: PortFolioData;
  activeTab: number;
  chartType: "contributed_by_fa_upgrade" | "fa_upgrades_vs_new_customers";
  multiplyValue: number;
};

const AreaChartUI = ({
  country,
  data,
  activeTab,
  activeCurrency,
  chartType,
  multiplyValue,
}: ChartDataProps) => {
  // cb
  const formatterCbY = function (value: number) {
    // used for the y axis label text's formatting
    return `${value} ${formatterJoinVal}`;
  };

  const convertGnAmountToActiveCurrency = (
    data: number[] // currency converted data
  ) => data.map((d) => Number((d * multiplyValue).toFixed(0)));

  const getJoinVal = () => {
    // used for the y axis label text's formatting
    if (chartType === "fa_upgrades_vs_new_customers")
      return "%"; // for fa_upgrades_vs_new_customers chart type
    else if (chartType === "contributed_by_fa_upgrade" && activeTab === 1)
      return ""; // for contributed_by_fa_upgrade chart type and if the count tab is selected
    else if (country === "*") return "USD"; // for global dd value got selected
    else return activeCurrency;
  };

  const getDataToChartSeries = () => {
    // at initial both chart's data is fetched, after that depends on the chart and tab type data value is picked from the res
    if (chartType === "contributed_by_fa_upgrade" && activeTab === 0)
      return convertGnAmountToActiveCurrency(data.amount);
    else if (chartType === "contributed_by_fa_upgrade" && activeTab === 1)
      return data.count;
    else return data.percentage;
  };

  // const
  const formatterJoinVal = getJoinVal();
  const dataToChartSeries = getDataToChartSeries();
  const seriesName =
    chartType === "contributed_by_fa_upgrade"
      ? "FA Upgrade"
      : "FA Upgrade Vs New Customers";

  // hook
  const { options } = useGetAreaChart({
    yAxisTitle: "Existing Customers Upgrades",
    formatterCbY,
    categories: data.month,
    colors: ["#10B277"],
  });

  const series = [
    {
      name: seriesName,
      data: dataToChartSeries,
    },
  ];

  return (
    <div id="chart">
      <ReactApexChart
        type="area"
        series={series}
        options={options}
        height={400}
      />
      <div id="html-dist"></div>
    </div>
  );
};
