import { configureStore } from '@reduxjs/toolkit';
import CommonReducer, { rehydrate } from './Slices/CommonSlice';
import CommonSearchReducer from './Slices/CommonSearchSlice';
import HeaderSlice from './Slices/HeaderSlice';



const store = configureStore({
  reducer: {
    common_slice:CommonReducer,
    HeaderSlice:HeaderSlice,
    cs_soft_phone: CommonReducer,
    CommonSearch : CommonSearchReducer,
  },

});



// const persistor = persistStore(store);
export { store };



