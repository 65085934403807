import { ERROR_SHORT_INFO, FETCHING_SHORT_INFO } from "../../../consts";
import { RhfOptionTypeDataKeyOmited } from "../../../type";
import FixedLabelRhfAutoComplete from "../rhfComponents/fixedLabelRhfAutoComplete";
import useGetRegionsListViaQuery from "./queryHooks/useGetRegionsListViaQuery";

/**
 *
 * @param param0 name which one is used for the rhf key
 * @param param1 label which one is used for the field label
 * @param param2 requied which one is used show the * in label
 * @param param3 customOnchangeCb this is an optional field if you want to update some other fields along with the current field's onchange. then pass the customOnchange fn from parent component, during onchange that fn will triggered
 * @returns this AutoComplete is used to cache the data regions list from api, *for working correctly this should be wrapped inside the rhf
 */
const RegionWithFixedLabelAutoComplete = ({
  name,
  label,
  required,
  customOnchangeCb,
}: {
  name: string;
  label?: string;
  required?: boolean;
  customOnchangeCb?: Function;
}) => {
  // query hook
  const { regionList, isError } = useGetRegionsListViaQuery();

  const errorCaseString = isError ? ERROR_SHORT_INFO : FETCHING_SHORT_INFO;

  // options
  const regionOptions: RhfOptionTypeDataKeyOmited[] = [
    { name: "Choose", id: "choose", actualId: "choose" },
    ...((regionList &&
      regionList?.data?.list
        .map((l) => ({
          name: l.data_value,
          id: l.data_code,
          actualId: l.id,
        }))
        .sort(
          // sorting by alphabetic asc
          (a, b) => a.name.localeCompare(b.name, "en", { sensitivity: "base" })
        )) || [
      {
        name: errorCaseString,
        id: errorCaseString,
        actualId: errorCaseString,
      },
    ]),
  ];

  return (
    <FixedLabelRhfAutoComplete
      name={name}
      label={label}
      variant="standard"
      required={required!!}
      options={regionOptions}
      sx={{
        "& .MuiInputBase-input": {
          // adds pad to input
          py: "8px !important",
          color: "#fff",
          width: "100% !important",
          fontFamily: "Montserrat",
        },
      }}
      getOptionLabel={(option: RhfOptionTypeDataKeyOmited | string) =>
        typeof option === "object" ? option.name : option
      }
      getOptionKey={(option: RhfOptionTypeDataKeyOmited | string) =>
        typeof option === "object" ? option.actualId : option
      }
      isOptionEqualToValue={(option, value) =>
        typeof option === "object" && typeof value === "object"
          ? option.id === value.id
          : option === value
      }
      disableClearable
      customOnchangeCb={
        customOnchangeCb
      }
    />
  );
};

export default RegionWithFixedLabelAutoComplete;
