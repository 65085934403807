import { useQuery } from "@tanstack/react-query";
import { getListByUrl } from "../../../../actions/common_actions";
import { common_req } from "../../../../helpers/common_helper";
import { RhfOptionTypeInTermsOfDataKey } from "../../../../type";

type PovinceGetResProps = { data: { list: RhfOptionTypeInTermsOfDataKey[] } };

/**
 *
 * @returns province list and it's loading and error state
 */
const useGetProvinceListViaQuery = () => {
  // const
  const reqObj = common_req({} as any);
  delete reqObj.access_token; // delete access_token key from reqObj some apis take token while other apis take access_token here no need for that (in reqObj both token and access_token keys will be there)

  // query
  const {
    data: provinceList,
    isError,
    isLoading,
    isFetched,
  } = useQuery<PovinceGetResProps>({
    queryKey: ["list_of_province"],
    queryFn: () =>
      getListByUrl("common", "dropdown", {
        ...reqObj,
        field_code: "province",
        status: "enabled",
      }),
    staleTime: 1000 * 60 * 5,
  });

  return { provinceList, isError, isLoading, isFetched };
};

export default useGetProvinceListViaQuery;
