import { useQuery } from "@tanstack/react-query";
import { getReport } from "../../actions/report_actions";
import { ERROR_INFO, FETCHING_INFO } from "../../consts";
import { common_req } from "../../helpers/common_helper";
import { RhfOptionType } from "../../type";
import FormSection from "./components/formSection";

export type Option = {
  country_code: string;
  id: string;
  name: string;
};

type TableauDataProps = {
  title: string;
  last_refreshed_at: string;
  country_code: string;
  link: string;
  status: number;
};

type TableauResProps = {
  data: TableauProps;
};

export type TableauProps = {
  country_codes: RhfOptionType[];
  options: Option[];
  tableaus: Record<string, TableauDataProps>;
};

const Tableau = () => {
  // const
  const reqObj = common_req({} as any);

  // query
  const {
    data: tableauData,
    isLoading,
    isError,
  } = useQuery<TableauResProps>({
    queryKey: ["tableau"],
    queryFn: () =>
      getReport({
        ...reqObj,
        report_type: "tableau",
      }),
  });

  if (isLoading) return <>{FETCHING_INFO}</>;

  if (isError) return <>{ERROR_INFO}</>;

  return <FormSection tableauRes={tableauData?.data!} />;
};

export default Tableau;
