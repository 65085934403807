import { ERROR_SHORT_INFO, FETCHING_SHORT_INFO } from "../../../consts";
import { RhfOptionTypeDataKeyOmited } from "../../../type";
import FixedLabelRhfAutoComplete from "../rhfComponents/fixedLabelRhfAutoComplete";
import useGetCountyListViaQuery from "./queryHooks/useGetCountyListViaQuery";

/**
 *
 * @param param0 name which one is used for the rhf key
 * @param param1 label which one is used for the field label
 * @param param2 requied which one is used show the * in label
 * @returns this AutoComplete is used to cache the county lists data from api, *for working correctly this should be wrapped inside the rhf
 */
const CountyWithFixedLabelAutoComplete = ({
  name,
  label,
  required,
  parentDataCode,
  disabled,
}: {
  name: string;
  label?: string;
  parentDataCode: string;
  required?: boolean;
  disabled?: boolean;
}) => {
  // query hook
  const { countyList, isError, isLoading } = useGetCountyListViaQuery({
    parent_data_code: parentDataCode,
  });

  const errorCaseString = isError ? ERROR_SHORT_INFO : FETCHING_SHORT_INFO;

  // options
  const countyOptions: RhfOptionTypeDataKeyOmited[] = [
    { name: "Choose", id: "choose", actualId: "choose" },
    ...((countyList &&
      countyList?.data?.list
        .map((l) => ({
          name: l.data_value,
          id: l.data_code,
          actualId: l.id,
        }))
        .sort(
          // sorting by alphabetic asc
          (a, b) => a.name.localeCompare(b.name, "en", { sensitivity: "base" })
        )) || [
      {
        name: errorCaseString,
        id: errorCaseString,
        actualId: errorCaseString,
      },
    ]),
  ];

  return (
    <FixedLabelRhfAutoComplete
      name={name}
      label={label}
      variant="standard"
      required={required!!}
      options={countyOptions}
      sx={{
        "& .MuiInputBase-input": {
          // adds pad to input
          py: "8px !important",
          color: "#fff",
          width: "100% !important",
          fontFamily: "Montserrat",
        },
      }}
      getOptionLabel={(option: RhfOptionTypeDataKeyOmited | string) =>
        typeof option === "object" ? option.name : option
      }
      getOptionKey={(option: RhfOptionTypeDataKeyOmited | string) =>
        typeof option === "object" ? option.actualId : option
      }
      isOptionEqualToValue={(option, value) =>
        typeof option === "object" && typeof value === "object"
          ? option.id === value.id
          : option === value
      }
      disabled={isLoading || disabled}
      loading={isLoading}
      disableClearable
    />
  );
};

export default CountyWithFixedLabelAutoComplete;
