import { ReactNode } from "react";
import { ERROR_SHORT_INFO, FETCHING_SHORT_INFO } from "../../../consts";
import { RhfOptionType } from "../../../type";
import RHFAutocomplete from "../rhfComponents/rhfAutoComplete";
import useGetAuditorsListViaQuery from "./queryHooks/useGetAuditorsListViaQuery";

/**
 *
 * @param param0 name which one is used for the rhf key
 * @param param1 label which one is used for the field label
 * @param param2 actAs which one is used to keep the default dd icon or filter icon
 * @param param3 roleCodes which one is used for the filter
 * @returns this AutoComplete is used to cache the auditors data from api, *for working correctly this should be wrapped inside the rhf
 */
const AuditorsListAutoComplete = ({
  name,
  label,
  endIcon,
  actAs,
  roleCodes,
}: {
  name: string;
  label?: string;
  endIcon?: ReactNode;
  actAs: "dd" | "filter";
  roleCodes: string[];
}) => {
  // query
  const { auditorsList, isError } = useGetAuditorsListViaQuery(roleCodes);

  const errorCaseString = isError ? ERROR_SHORT_INFO : FETCHING_SHORT_INFO;

  // options
  const auditorsOptions: RhfOptionType[] = [
    { name: "All (auditors)", id: "all" },
    ...((auditorsList &&
      auditorsList?.data.list
        .map((l) => ({
          name: l.name.split(" ")[0], // Split the name and take the first part
          id: l.id,
        }))
        .sort(
          // sorting by alphabetic asc
          (a, b) => a.name.localeCompare(b.name, "en", { sensitivity: "base" })
        )) || [
      {
        name: errorCaseString,
        id: errorCaseString,
      },
    ]),
  ];

  return (
    <RHFAutocomplete
      name={name}
      label={label!!}
      options={auditorsOptions}
      sx={{
        // overides the end icon style
        "& .MuiButtonBase-root": {
          ...(actAs === "filter" && { transform: "none" }),
          svg: {
            width: "20px",
            height: "20px",
            color: (theme) =>
              // @ts-ignore
              theme.palette.custom_colors.secondary_a_200,
          },
        },
      }}
      popupIcon={endIcon!!}
      getOptionLabel={(option: RhfOptionType | string) =>
        typeof option === "object" ? option.name : option
      }
      getOptionKey={(option: RhfOptionType | string) =>
        typeof option === "object" ? option.id : option
      }
      isOptionEqualToValue={(option, value) =>
        typeof option === "object" && typeof value === "object"
          ? option.id === value.id 
          : option === value
      }
      disableClearable
    />
  );
};

export default AuditorsListAutoComplete;
