import { ReactNode, useCallback, useEffect, useReducer, useRef } from "react";
import {
  AlertState,
  AlertTriggerKeysProp,
  DispatchProps,
  HmeAlrtCtx,
  TRIGGER_MODAL,
} from "./hmeAlrtCtx";

function reducer(state: AlertState, action: DispatchProps) {
  const { type, payload } = action;
  switch (type) {
    case TRIGGER_MODAL:
      return {
        ...state,
        showModal: payload.show,
        msg: payload.msg,
        forTable: payload.forTable,
      };

    default:
      return state;
  }
}

const initialState: AlertState = {
  showModal: false,
  msg: "",
  forTable: "",
};

const HmeAlrtCtxProvider = ({ children }: { children: ReactNode }) => {
  //reducer
  const [hmeAlrtState, dispatch] = useReducer(reducer, initialState);

  // ref
  const bcRef = useRef<BroadcastChannel | null>(null);

  // effect
  useEffect(() => {
    // initialize channel to ref
    bcRef.current = new BroadcastChannel("Home_Alert_Bc");

    // on message event
    if (bcRef.current) {
      bcRef.current.onmessage = (event) =>
        dispatch({
          type: TRIGGER_MODAL,
          payload: {
            show: event.data.key === "OPEN",
            msg: event.data.payload.msg,
            forTable: "",
          },
        }); // opens or close depends on message one of *OPEN or *CLOSE
    }

    // cleanup
    return () => {
      if (bcRef.current) bcRef.current.close();
    };
  }, []);

  // handlers
  const openAlert = useCallback(
    ({ msg, forTable }: Omit<AlertState, "showModal">) => {
      dispatch({
        type: TRIGGER_MODAL,
        payload: {
          show: true,
          msg,
          forTable,
        },
      }); // opens or close depends on message one of *Open or *Close
    },
    []
  );

  const clearAlert = useCallback(({ forTable }: { forTable: string }) => {
    dispatch({
      type: TRIGGER_MODAL,
      payload: {
        show: false,
        msg: "",
        forTable,
      },
    }); // opens or close depends on message one of *Open or *Close
  }, []);

  const triggerChannel = useCallback(
    ({
      key,
      msg,
      forTable,
    }: {
      key: AlertTriggerKeysProp;
      msg: string;
      forTable: string;
    }) => {
      // locally show or close the alet for the current tab because the boradcast not trigger the event for the same tab from where the event was triggered
      if (key === "OPEN")
        openAlert({
          msg,
          forTable,
        });
      else clearAlert({ forTable });
      // this will trigger the alert for other tabs if any other tabs are opened
      bcRef?.current?.postMessage({
        key,
        payload: { msg },
      });
    },
    []
  );

  return (
    <HmeAlrtCtx.Provider
      value={{
        hmeAlrtState,
        dispatch,
        bcRef,
        methods: {
          openAlert,
          clearAlert,
          triggerChannel,
        },
      }}
    >
      {children}
    </HmeAlrtCtx.Provider>
  );
};

export default HmeAlrtCtxProvider;
