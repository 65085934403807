import { Box, Grid } from "@mui/material";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import CountryWithFixedLabelAutoComplete from "../../../CommonComponent/commonAutoCompleteDD/countryWithFixedLabelAutoComplete";
import DistrictWithFixedLabelAutoComplete from "../../../CommonComponent/commonAutoCompleteDD/districtWithFixedLabelAutoComplete";
import LocationWithFixedLabelAutoComplete from "../../../CommonComponent/commonAutoCompleteDD/locationWithFixedLabelAutoComplete";
import ProvinceWithFixedLabelAutoComplete from "../../../CommonComponent/commonAutoCompleteDD/provinceWithFixedLabelAutoComplete";
import SectorWithFixedLabelAutoComplete from "../../../CommonComponent/commonAutoCompleteDD/sectorWithFixedLabelAutoComplete copy";
import TerritoryWithFixedLabelAutoComplete from "../../../CommonComponent/commonAutoCompleteDD/territaryWithFixedLabelAutoComplete";
import FixedLabelRhfLocationField from "../../../CommonComponent/rhfComponents/fixedLabelRhfLocationField";
import FixedLabelRhfTextField from "../../../CommonComponent/rhfComponents/fixedLabelRhfTextField";

const BorrowerCommonAddressFormUIRWA = ({
  disableForm,
  addressViewFor,
}: {
  disableForm: boolean;
  addressViewFor: "CUR_RES_ADD" | "PER_ADD" | "BIZ_ADD";
}) => {
  // hook form context
  const { watch, setValue } = useFormContext();

  // handler
  const customOnchangeCbForProvince = useCallback(() => {
    setValue(
      "district",
      { id: "choose", name: "Choose" },
      {
        shouldValidate: true,
        shouldDirty: true,
      }
    );
    setValue(
      "sector",
      { id: "choose", name: "Choose" },
      {
        shouldValidate: true,
        shouldDirty: true,
      }
    );
  }, []);

  const customOnchangeCbForDistrict = useCallback(() => {
    setValue(
      "sector",
      { id: "choose", name: "Choose" },
      {
        shouldValidate: true,
        shouldDirty: true,
      }
    );
  }, []);

  // const
  const province = watch("province");
  const district = watch("district");
  const districtParentDataCode = province?.id;
  const countyParentDataCode = district?.id;

  return (
    <Box className={disableForm ? "disable_div" : ""}>
      <Grid container columnSpacing={"20px"} mt={"20px"}>
        <Grid item xs={12} md={2} lg={3}>
          <CountryWithFixedLabelAutoComplete
            name="country"
            label="country"
            disabled
            required
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={"20px"} rowGap={"20px"} mt={"20px"}>
        <Grid item xs={12} md={6} lg={3}>
          <ProvinceWithFixedLabelAutoComplete
            name="province"
            label="province"
            required
            customOnchangeCb={customOnchangeCbForProvince}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <DistrictWithFixedLabelAutoComplete
            name="district"
            label="district"
            parentDataCode={districtParentDataCode}
            customOnchangeCb={customOnchangeCbForDistrict}
            disabled={!province || province?.id === "choose"}
            required
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <SectorWithFixedLabelAutoComplete
            name="sector"
            label="sector"
            parentDataCode={countyParentDataCode}
            disabled={!district || district?.id === "choose"}
            required
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <LocalTextField label="cell" name="cell" required />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <LocalTextField label="village" name="village" />
        </Grid>
        {/* <Grid item xs={12} md={6} lg={3}>
          <TerritoryWithFixedLabelAutoComplete
            name="territory"
            label="territory"
            required
          />
        </Grid> */}
        <Grid item xs={12} md={6} lg={3}>
          <LocationWithFixedLabelAutoComplete
            name="location"
            label="location"
            required
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <LocalTextField label="landmark" name="landMark" required />
        </Grid>
        {addressViewFor === "CUR_RES_ADD" && (
          <Grid item xs={12} md={6} lg={3}>
            <LocalTextField
              label="confirmed by"
              name="verificationMethod"
              disabled
            />
          </Grid>
        )}
        {addressViewFor === "BIZ_ADD" && (
          <Grid item xs={12} md={6} lg={3}>
            <FixedLabelRhfLocationField
              variant="standard"
              label="gps"
              name="gps"
              required
              sx={{
                "& .MuiInputBase-input": {
                  // adds pad to input
                  py: 1,
                  color: "#fff",
                  fontFamily: "Montserrat",
                },
              }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const LocalTextField = ({
  name,
  label,
  required,
  disabled,
}: {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
}) => (
  <FixedLabelRhfTextField
    variant="standard"
    label={label}
    name={name}
    autoComplete="undefined"
    required={required!!}
    sx={{
      "& .MuiInputBase-input": {
        // adds pad to input
        py: 1,
        color: "#fff",
        fontFamily: "Montserrat",
      },
    }}
    disabled={disabled!!}
  />
);

export default BorrowerCommonAddressFormUIRWA;
