import { useQuery } from "@tanstack/react-query";
import { getListByUrl } from "../../../../actions/common_actions";
import { common_req } from "../../../../helpers/common_helper";
import { RhfOptionTypeInTermsOfDataKey } from "../../../../type";
type LocationGetResProps = { data: { list: RhfOptionTypeInTermsOfDataKey[] } };

/**
 * 
 * @returns location list and it's loading and error state
 */
const useGetLocationViaQuery = () => {
  // const
  const reqObj = common_req({} as any);
  delete reqObj.access_token; // delete access_token key from reqObj some apis take token while other apis take access_token here no need for that (in reqObj both token and access_token keys will be there)

  // query
  const {
    data: locationList,
    isError,
    isLoading,
    isFetched,
  } = useQuery<LocationGetResProps>({
    queryKey: ["list_of_location"],
    queryFn: () =>
      getListByUrl("common", "dropdown", {
        ...reqObj,
        field_code: "location",
        status: "enabled",
      }),
    staleTime: 1000 * 60 * 5,
  });

  return { locationList, isError, isLoading, isFetched };
};

export default useGetLocationViaQuery;
