import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid } from "@mui/material";
import { ReactNode, useCallback, useState } from "react";
import { FormProvider as Form, useForm } from "react-hook-form";
import * as Yup from "yup";
import { update } from "../../../../actions/common_actions";
import { REQUIRED } from "../../../../consts";
import { common_req } from "../../../../helpers/common_helper";
import { requiredRecordValidation } from "../../../../helpers/helper";
import { check_priv } from "../../../../helpers/storage_helper";
import { SubTitleVarientOneTypo } from "../../../CommonComponent/typography";
import BorrowerCommonAddressFormUIMadagascar from "../marketBasedFormUI/BorrowerCommonAddressFormUIMadagascar";

type InitialValProps = {
  regionVal: Record<string, string>;
  countryVal: Record<string, string>;
  communeVal: Record<string, string>;
  districtVal: Record<string, string>;
  village: string;
  gps: string;
};

const FormSchema = Yup.object().shape({
  region: requiredRecordValidation,
  country: requiredRecordValidation,
  commune: requiredRecordValidation,
  district: requiredRecordValidation,
  village: Yup.string().required(REQUIRED),
  gps: Yup.string(),
});

/**
 *
 * @param param0 title the header of the view
 * @param param1 addressId need to send in update api payload
 * @param param2 entity used to check the user privileage to show the edit btn
 * @param param3 custId need to send for the update api
 * @param param4 addressViewFor the current res address and biz address have the location pick option, to show or hide the location field this field is used,
 * @returns
 */
const BorrowerCommonAddressEditUIMadagascar = ({
  title,
  addressId,
  entity,
  custId,
  addressViewFor,
  initialVal,
}: {
  title: string;
  addressId: string;
  entity: string;
  custId: string;
  addressViewFor: "CUR_RES_ADD" | "PER_ADD" | "BIZ_ADD";
  initialVal: InitialValProps;
}) => {
  // state
  const [isFormSubmitting, setisFormSubmitting] = useState(false);

  // form default values
  const defaultValues = {
    country: initialVal.countryVal,
    region: initialVal.regionVal,
    commune: initialVal.communeVal,
    district: initialVal.districtVal,
    village: initialVal.village,
    gps: initialVal.gps,
  };

  // hookForm
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  // rhf returns
  const {
    handleSubmit,
    reset,
    formState: { isDirty, isValid },
    getValues,
  } = methods;

  // state
  const [disableForm, setdisableForm] = useState(true);

  /**
   * gets the value via param, if the value is "choose" null is returned
   */
  const checkIfChooseString = useCallback(
    (val: string) => (val === "choose" ? null : val),
    []
  );

  // handler
  const onSubmit = handleSubmit((formData) => {
    setisFormSubmitting(true);
    // payload
    setTimeout(() => {
      const req = common_req({} as any);
      const address: Record<string, any> = {
        source: "profile",
        region: checkIfChooseString(formData.region.id),
        commune: checkIfChooseString(formData.commune.id),
        country: checkIfChooseString(formData.country.id),
        district: checkIfChooseString(formData.district.id),
        village: formData.village,
        id: addressId,
        ...(addressViewFor === "BIZ_ADD" && {
          // gps only for biz address
          gps: formData.gps,
        }),
      };
      // update api
      update("address", { ...req, address, status: null }).then((res) => {
        if (res?.status === "success") {
          alert(res?.message);
          setdisableForm(true);
          setisFormSubmitting(false);
          reset(formData); // resetting the form
        } else {
          // alert(res?.message || ERROR_INFO);
          setisFormSubmitting(false);
        }
      });
    }, 1000);
  });

  return (
    <Form {...methods}>
      {/* header */}
      <HeaderBgWrapper>
        <Grid container gap={"15px"} display="flex" alignItems="center">
          <Grid item xs={10} md={4}>
            <SubTitleVarientOneTypo
              text={title}
              overRideStyles={{
                fontFamily: "Montserrat",
                color: "#fff",
                textTransform: "capitalize",
              }}
            />
          </Grid>
          <Grid item xs={2} md={1}>
            {disableForm && check_priv(entity, "edit") && (
              <Button
                type="button"
                variant="contained"
                sx={{
                  backgroundColor: "#999999",
                  color: (theme) => theme.palette.text.primary,
                  p: "5px",
                  minWidth: "auto",
                  boxShadow: "none",
                  ":hover": {
                    backgroundColor: "#6c757d",
                  },
                }}
                onClick={() => setdisableForm(false)}
              >
                Edit
              </Button>
            )}
            {!disableForm && (
              <Button
                variant="contained"
                onClick={onSubmit}
                disabled={isFormSubmitting || !isDirty || !isValid}
                sx={{
                  backgroundColor: "#4caf50",
                  color: (theme) => theme.palette.text.primary,
                  p: "5px",
                  minWidth: "auto",
                  boxShadow: "none",
                  ":hover": {
                    backgroundColor: "#388e3c",
                  },
                }}
              >
                {isFormSubmitting ? "Pls wait..." : "Save"}
              </Button>
            )}
          </Grid>
        </Grid>
      </HeaderBgWrapper>
      {/* form ui */}
      <Box sx={{ p: 2 }}>
        <BorrowerCommonAddressFormUIMadagascar
          disableForm={disableForm}
          addressViewFor={addressViewFor}
        />
      </Box>
    </Form>
  );
};

export default BorrowerCommonAddressEditUIMadagascar;

/**
 *
 * @param param0 react children
 * @returns bg styled box component
 */
const HeaderBgWrapper = ({ children }: { children: ReactNode }) => (
  <Box sx={{ p: 1, backgroundColor: "#3D3E53" }}>{children}</Box>
);
