import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { TableauProps } from "..";

const TableauSection = ({ tableauRes }: { tableauRes: TableauProps }) => {
  // form ctx
  const { watch } = useFormContext();

  const activeTableau = watch("active_tableau");

  return (
    <Box
      sx={{
        py: "15px",
        height: "100vh",
        width: "100%",
      }}
    >
      <iframe
        src={`${
          tableauRes.tableaus[activeTableau.name].link
        }?:showVizHome=no&:embed=true`}
        width="100%"
        height="100%"
        title="Tableau Dashboard"
        allowFullScreen
        style={{ border: "none" }}
      />
    </Box>
  );
};

export default TableauSection;
