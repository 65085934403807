import { useQuery } from "@tanstack/react-query";
import { getListByUrl } from "../../../../actions/common_actions";
import { common_req } from "../../../../helpers/common_helper";
import { RhfOptionType } from "../../../../type";

type CountrysGetResProps = { data: { list: RhfOptionType[] } };

/**
 * 
 * @returns country list and it's loading and error state
 */
const useGetCountryListViaQuery = () => {
  // const
  const reqObj = common_req({} as any);

  // query
  const {
    data: countryList,
    isError,
    isLoading,
    isFetched,
  } = useQuery<CountrysGetResProps>({
    queryKey: ["list_of_country"],
    queryFn: () => getListByUrl("common", "country", reqObj),
    staleTime: 1000 * 60 * 5,
  });
  return { countryList, isError, isLoading, isFetched };
};

export default useGetCountryListViaQuery;
