import _ from 'lodash';
import { Navigate } from 'react-router-dom';
import { createBorrower, viewBorrower } from '../../../actions/borrower_actions';
import withRouter from '../../../Router/Withrouter';
import '../../../styles/flow.css';
import AddressInfo from '../../common/component/AddressInfo';
import Person from '../../common/component/Person';
import FlowContainer from '../../common/container/core/FlowContainer';
import Borrower from '../component/Borrower';
import BorrowerAddressEditContainer from '../component/marketBasedContainer';

class IndividualBorrowerContainer extends FlowContainer {
  //
  
  state = {
    biz_type : 'individual',
    logout : super.touch_async(),
    toList: false,   
    cust_id: null,
    disburse_style: "",
    biz_address: {touch: null},
    owner_person : {touch: null},
    contact_person : {touch: null},
    owner_address: {touch: null},
    contact_address: {touch: null},
    permanent_address: null,
    owner_address_from_res: null,
    borrower: {},
    person_div_style: "show_div",
    send_country_code: true,
    same_as_biz_address: false,
    acc_num_check_box:'',
    thirdparty_details:'',
    pointer_evt :''
  }

  componentWillMount() {
    if(this.props.mode === 'view'){
      this.cust_id = this.props.params.cust_id;
      viewBorrower(this.req({cust_id: this.cust_id, screen: "edit"}))
        .then((response) => {
          if(!response){return };
            this.borrower = response.data;
            const op = this.borrower.owner_person
            const oa = op ?  op.owner_address : null
            var contact_persons = this.borrower.contact_persons ? this.borrower.contact_persons :{}
            var contact_address = {}
            if(contact_persons.length > 0){
              contact_persons.map((contact_person) => 
                this.setState({ contact_persons : contact_person,contact_address : contact_person.contact_address})
              )
            }
            this.setState({borrower: this.borrower,
                            biz_address: {...this.borrower.biz_address,  
                            territory: this.borrower.territory},
                            owner_person: op,
                            owner_address: {...oa,
                            territory: this.borrower.territory},
                            biz_type: this.borrower.biz_type,
                            thirdparty_details:this.borrower.third_party?this.borrower.third_party:null,
                            cust_id : this.cust_id,
                            permanent_address: response?.data.owner_person?.permanent_res_addr,
                            owner_address_from_res: {...response?.data.owner_person?.owner_address, verification_method: response?.data?.kyc_addr_verf_method}
                           });
            if(this.state.borrower.profile_status == 'closed'){
              this.setState({pointer_evt : 'pointer_evt'});
            }               
          }
        );
    }else if(this.props.mode  === 'create'){
     
    }
  } 

  handleSubmit = (event)=>{
    event.preventDefault();
    var borrower = this.state.borrower;  
    borrower.country_code = this.country_code;
    //borrower.biz_type = "Individual";
    borrower.biz_address = this.state.biz_address;
    borrower.biz_address.country_code = this.country_code;
    borrower.owner_person = this.state.owner_person;
    borrower.owner_person.owner_address = this.state.owner_address;
    borrower.owner_person.owner_address.country_code = this.country_code;  
    borrower.owner_person.country_code = this.country_code;
    if(this.state.borrower.business_distance == "business_at_home"){
      borrower.same_as_biz_address = true
    }else{
      borrower.same_as_biz_address = false
    }
    
    this.setState({disburse_style : "disable_div"});
    createBorrower(this.req({borrower}, true))
        .then((response) => {
          this.setState({disburse_style : ""});
          if(!response){
            
            return 
          };
            if(response.status === "success"){
              this.setState({cust_id: response.data});
              this.setState({toList: true});
              alert("New Customer created successfully");
            }else{
              
              alert(response.message + "\n" + JSON.stringify(response.data));  
            }

          }
        );
  }
  

  

  
  handleCustTypeChange = (event) => {
    
    this.setState({biz_type : event.target.value})
  }
  
  handleCheck = (checkbox_check) =>{
   
    if(checkbox_check == true)
    {
      this.state.acc_num_check_box = true;
    }
    else
    {
      this.state.acc_num_check_box = false;
    }

  }


 
  render(){
    if(this.state.borrower.business_distance == "business_at_home"){
      var address_div_style = "hide_div"
    }else{
      var address_div_style = "show_div"

    }

    this.state.logout.then((value) => this.logout = value)
    if(this.logout){
       return <></>
    }
    
    if (this.state.toList === true) {
      return <Navigate to={`/borrower/indiv/edit/${this.state.cust_id}`}/> 
    }
      return (
        <>        

      <div className="container date-range max-width containerTopmargin no-padding">
         <h5 className="no-margin headerTitle headerPadvert">{this.props.title + (this.state.borrower.name? " : " + this.state.borrower.name : "") +" "+(this.cust_id? this.cust_id : "") + (this.state.borrower.acc_number ? " / " + this.state.borrower.acc_number : "")}</h5>
          <form id="borrower_form" class="borrower_form" onSubmit={this.handleSubmit}>
            <div id="" className={`${this.state.pointer_evt} loggedPersondetailsBox`}>
                { (this.props.mode == 'create' || !_.isEmpty(this.state.borrower)) &&  
                
                <Borrower id="borrower" data={this.state.borrower} onCustTypeChange={this.handleCustTypeChange} entity="customer" screen={this.props.screen} mode={this.props.mode} onComponentChange={this.handleComponentChange} onCheck ={this.handleCheck}/>
                
                }                
                {this.state.biz_type == 'individual' &&
                  <>
                  {/* <AddressInfo id="biz_address" screen="borrower" title="Business Address" entity="customer"  gps={this.state.gps} data={this.state.biz_address} cust_id={this.cust_id} mode={this.props.mode}  onComponentChange={this.handleComponentChange}
                  onComponentReset={this.handleComponentReset}/> */}
                  {(this.state.biz_address) &&
                    <BorrowerAddressEditContainer title={"Business Address"} address_obj={this.state.biz_address} entity="customer" cust_id={this.cust_id} address_view_for="BIZ_ADD" />
                  }
                  <Person id="owner_person" title="Business Owner Details" val="Business Owner Details" entity="customer" data={this.state.owner_person} mode={this.props.mode} cust_id={this.cust_id} onComponentChange={this.handleComponentChange}/>                  
                  {this.state.thirdparty_details && <Person id="thirdparty_details" title="Third Party A/C Owner" val="Third Party A/C Owner" entity="customer" data={this.state.thirdparty_details} mode={this.props.mode} cust_id={this.state.cust_id} onComponentChange={this.handleComponentChange}/>}                  
                  </>
                }
                {/* {(this.state.biz_type == 'individual' && this.props.mode === "view") &&
                  <div className={address_div_style}>
                    <AddressInfo id="owner_address" entity="customer" title="Current Residential Address" data={this.state.owner_address} cust_id={this.cust_id}  mode={this.props.mode} onComponentChange={this.handleComponentChange} onComponentReset={this.handleComponentReset}/>                  
                  </div>
                }  */}
                {(this.state.biz_type == 'individual' && this.props.mode === "view" && this.state.owner_address_from_res) &&
                  <BorrowerAddressEditContainer title={"Current Residential Address"} address_obj={this.state.owner_address_from_res} entity="customer" cust_id={this.cust_id} address_view_for="CUR_RES_ADD" />  
                }
                {(this.state.biz_type == 'individual' && this.props.mode === "view" && this.state.permanent_address) &&
                  <BorrowerAddressEditContainer title={"Permanent Residential Address"} address_obj={this.state.permanent_address} entity="customer" cust_id={this.cust_id} address_view_for="PER_ADD" />  
                }
                {(this.state.biz_type == 'individual' && this.props.mode !== "view") &&
                  <div>
                  
                  <div className={address_div_style} >
                  <label className="checkbox-inline">
                        <h4>Business Owner Address</h4>
                    </label>
                    <AddressInfo id="owner_address" screen="borrower" entity="customer" data={this.state.owner_address} cust_id={this.cust_id} mode={this.props.mode} onComponentChange={this.handleComponentChange} onComponentReset={this.handleComponentReset}/>        
                  </div>
                  </div>
                }
                {this.state.biz_type == 'individual' && !_.isEmpty(this.state.contact_persons) &&                
                  <>                    
                    <Person id="contact_person" title="Handler Details" val="Contact Person Details" entity="customer" data={this.state.contact_person} cust_id={this.cust_id} mode={this.props.mode} onComponentChange={this.handleComponentChange}/>
                    <AddressInfo id="contact_address" screen="borrower" title="Handler Address" entity="customer"  data={this.state.contact_address} cust_id={this.cust_id} mode={this.props.mode}  onComponentChange={this.handleComponentChange}
                      onComponentReset={this.handleComponentReset}/>
                    <br/>
                  </>                
                }    
              <input type="button" className={`btn btn-primary ${this.state.disburse_style} ${this.props.mode === "create" ? "show_button": "hide_button"}`} onClick={this.handleSubmit} value="Create New Customer" />
            </div>
          </form>          
      </div>
      </>

      );
  }
}

export default  withRouter (IndividualBorrowerContainer);