import { Box, FormLabel } from "@mui/material";
import RHFTextField, { RHFTextFieldProps } from "./rhfTextField";

const FixedLabelRhfTextField = ({
  name,
  helperText,
  type,
  ...other
}: RHFTextFieldProps) => {
  return (
    <>
      {/* label UI */}
      <FormLabel
        required={other.required}
        sx={{
          "& .MuiFormLabel-asterisk": {
            // adds style to *
            color: "#DC3545",
            fontSize: "18px",
          },
          // adds style to label
          fontSize: "10px",
          margin: 0,
          color: "#b0b7cd!important",
          textTransform: "uppercase",
        }}
      >
        {other.label}
      </FormLabel>
      {/* field UI */}
      <Box sx={{
          "& .MuiFormHelperText-root": {
            fontSize: "12px!important",
          },
        }}>
      <RHFTextField
        name={name}
        type={type}
        helperText={helperText}
        // removes label from other obj
        {...{ ...other, label: "" }}
      />
      </Box>
    </>
  );
};

export default FixedLabelRhfTextField;
