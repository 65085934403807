import { useQuery } from "@tanstack/react-query";
import { getListByUrl } from "../../../../actions/common_actions";
import { common_req } from "../../../../helpers/common_helper";
import { RhfOptionTypeInTermsOfDataKey } from "../../../../type";

type SectorGetResProps = { data: { list: RhfOptionTypeInTermsOfDataKey[] } };

/**
 *
 * @returns sector list and it's loading and error state
 */
const useGetSectorListViaQuery = ({
  parent_data_code,
}: {
  parent_data_code: string | null;
}) => {
  // const
  const reqObj = common_req({} as any);
  delete reqObj.access_token; // delete access_token key from reqObj some apis take token while other apis take access_token here no need for that (in reqObj both token and access_token keys will be there)

  // query
  const {
    data: sectorList,
    isError,
    isLoading,
    isFetched,
  } = useQuery<SectorGetResProps>({
    queryKey: ["list_of_sector", parent_data_code],
    queryFn: () =>
      getListByUrl("common", "dropdown", {
        ...reqObj,
        field_code: "sector",
        status: "enabled",
        parent_data_code,
      }),
    staleTime: 1000 * 60 * 5,
    enabled: parent_data_code && parent_data_code !== "choose" ? true : false, // from dropdown fields by default the "choose" id is populated but there is no need to fetch the api for {parent_data_code: "choose"} payload, so we have disabled the api call here
  });

  return { sectorList, isError, isLoading, isFetched };
};

export default useGetSectorListViaQuery;
