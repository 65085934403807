import React from 'react';
import FlowContainer from '../../common/container/core/FlowContainer';
import { viewRMTarget,updateRMTarget,checkTargets } from '../../../actions/relationship_manager_actions';
import Assign from '../component/Assign';
import '../../../styles/flow.css';
import { Card, Button, InputGroup, ThemeProvider } from 'react-bootstrap';
import { format } from 'date-fns';
import {Form} from 'react-bootstrap';
import CommonReactTable from '../../../helpers/commonReact_table';
import { getconfigdata} from '../../../actions/common_actions';
import moment, { suppressDeprecationWarnings } from 'moment';
import { check_priv,get } from '../../../helpers/storage_helper';
import { BasicReactTable } from '../../../helpers/react_table';
import { BsXCircle } from "react-icons/bs";
import { BsCheckCircle } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { containerClasses } from '@mui/material';



class Assign_container extends FlowContainer {


    state = {
        columns: [],
        lists: [],
        copy: [],
        show_target_text: false,
        add_targets : false,
        view :null,
        assigned: {},
        edited_target:{},
        editable : false ,   
        save :false,
        edit:false,
        next_targets:{},  
        edit_cur_values:false,
        edit_next_values:false,
        edit_cur_month:false,
        edit_next_month:false,
        edit_role:false,
        loder:false,
        resp:false, 
        searchValue: '' ,
        next_month_flag:false,
        editing:{},
        single_edit : {},
        default_update : true,
        edit_duration  : false,
        last_week_count: 0

    }

    componentWillMount() {
        this.get_config_lists()
    }

    get_config_lists  = () => {
        const req = { 
            country_code: "*",
            config: "rm_management.rm_target_edit_threshold"
        };
    
        getconfigdata(this.req(req))
            .then((response) => {
                if (!response) { return; }
    
                if (response.status === "success") {
                    const { start, end } = response.message;
    
                    const date = new Date();
                    let edit_duration = false; 
                    if (date.getDate() <= end) { 
                        edit_duration = true; 
                    }
    
                    this.setState({ last_week_count: start, edit_duration,edit_role: check_priv("rm_target", "edit") ? true : false }, () => {
                        this.handleCurDate();
                        this.handleButtonName();
                        this.checkIfLastWeek();
                    }); 

                }
            })

    }
    

    checkIfLastWeek = () => {
        const today = new Date();
        today.setDate(today.getDate());
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();

        const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

        const lastWeekStart = new Date(lastDayOfMonth);
        lastWeekStart.setDate(lastDayOfMonth.getDate() + this.state.last_week_count);

        if (today >= lastWeekStart && today <= lastDayOfMonth) {
            this.setState({ next_month_flag: true });
        } else {
            this.setState({ next_month_flag: false });
        }
    };

    handleComponentChange = (event, comp_id) => {
        const id = event.target.id;
        const value = event.target.value;
        this.setState({ [id]: value });
    }


    handleCurDate = () => {
        const date = new Date()
        date.setMonth(date.getMonth() );
        const cur_month = format(date, 'yyyy-MM-01')
        const month = new Date(cur_month);
        const month_name = date.toLocaleString('en-US', {month: 'long'})
        this.setState({past_target: false, show_target_text: false, add_targets: false,cur_month,view_month_name : month_name+" Month Targets" ,edit_cur_month: this.state.edit_role ? true :false,edit:false,edit_next_month:false,edit_next_values:false,save:false,single_edit:{},editable: false,full_edit:false})
        this.drawTable(cur_month)
    }

    showAddTarget = (event) => {
        const dt = new Date()   
        const date = new Date(dt.getFullYear(), dt.getMonth(), 1)
        const cur_month = format(date, 'yyyy-MM-01')
        const next_month = new Date(date.setMonth(date.getMonth()+1))
        const next_month_name = next_month.toLocaleString('en-US', {month: 'long'})
        this.setState({view_month_name : next_month_name+" Month Targets", show_target_text: true, add_targets:true, past_target : false,cur_month,edit_cur_month:false,edit_cur_values:false,edit_next_month:true,edit_next_values:false,save:false,single_edit:{},editable: false,full_edit:true})
        this.drawTable(cur_month);
    }

    showPastTarget = (event) => {
        this.setState({ past_target: true, show_target_text: false, add_targets: false,save:false,editable:false,loder:true},() => {
            this.setState(state => ({
              columns: this.columndatas(this.state.header),loder:false
            }))})
        // this.drawTable(this.state.cur_month)  
    }

    handleSearch = (event) => {
        const cur_date =  new Date()
        const next_month = cur_date.setMonth(cur_date.getMonth()+1)
        const date = new Date(this.state.past_month);
        if (((cur_date.getMonth()) === date.getMonth()+1) && this.state.edit_role && (cur_date.getFullYear() === date.getFullYear())){
            this.setState({edit_cur_month:true,edit:false,save:false,editable: false,full_edit:false})
        }
        else{
            this.setState({edit_cur_month:false,edit_cur_values:false,edit:false,edit_next_month:false,edit_next_values:false,save:false,editable: false,full_edit:true})
        }
        if(date <= next_month){
            const month_name = date.toLocaleString('en-US', {month: 'long'})
            this.setState({view_month_name : month_name+" Month Targets" })
            this.drawTable(this.state.past_month)  
        }else{
            this.drawTable(this.state.past_month)  
        }
        
    }

    add_defult_Target = (new_target, rel_mgr_id, originalValue) => {
        const target = Number(new_target);
        this.setState(prevState => {
            const assigned = { ...prevState.assigned }; 
            assigned[rel_mgr_id] = target;
            if ((target === 0 && (originalValue === 0 || !originalValue || originalValue === 'NA'))) {
                delete assigned[rel_mgr_id];
            }
            return { assigned };
        });
    };
    

    addTarget = (event,row) => {
        const rel_mgr_id = row.rm_id
        const target = Number(event.target.value)
        const assigned = this.state.assigned;
        assigned[rel_mgr_id] = target;
        this.setState({assigned})
    }

    handleSubmit = (event) => {
       const dt = new Date(Date.now());
       const date = new Date(dt.getFullYear(), dt.getMonth(), 1)
       const cur_month = format(date, 'yyyy-MM-01')
       const next_month = date.setMonth(date.getMonth()+1)
       const month = this.state.edit_cur_values ? cur_month : format(date, 'yyyy-MM-01')
       this.setState({show_target_text : this.state.edit_cur_values ? false:true, edit:true}, async () => {
        try {
            await updateRMTarget(this.req({
                data : {target :this.state.assigned, target_month : month, month : cur_month}
            }));
        } finally {
            this.handleView();
        }
       })
       
    }

    handleView = () =>{
        this.setState({show_target_text:this.state.edit_cur_values ? false:true, editable:false, save:false,edit_cur_values:false,edit_next_values:false},
            ()=>{
                    if(!this.state.show_target_text){
                    this.handleCurDate()
                }
                else{
                    this.showAddTarget()
                }
            })
    }

    handleButtonName(){
        const dt = new Date(Date.now());
        const date = new Date(dt.getFullYear(), dt.getMonth(), 1)
        const month_name = date.toLocaleString('en-US', {month: 'long'})
        const next_month = new Date(date.setMonth(date.getMonth()+1))
        const next_month_name = next_month.toLocaleString('en-US', {month: 'long'})
        this.setState({cur_month_name:month_name+' Targets',next_month_name : 'Update '+next_month_name+' Targets'})  
    }

    getPastTarget(){
        this.state.row_datas && this.state.row_datas.forEach((data) => {
            const rm_id = data.rm_id
            const assigned = this.state.assigned
            assigned[rm_id] = 0
           this.setState({assigned})
            
        });
    }

    getNextTarget(){
        this.state.row_datas && this.state.row_datas.forEach((data) => {
           
            const rm_id = data.rm_id
            const next_targets = this.state.next_targets
            if(data.next_targets !== null){
                this.setState(prevState => {
                    const next_targets = { ...prevState.next_targets };
                    next_targets[rm_id] = Number(data.next_targets);
                    return { next_targets };
                })
            }
            else{
                this.setState(prevState => {
                    const next_targets = { ...prevState.next_targets };
                    return { next_targets };
                },()=>{
                    if (Object.keys(this.state.next_targets).length === 0 && this.state.default_update) {
                    Object.values(this.state.row_datas).forEach(rm_target => {
                        const new_target = rm_target.targetted;
                        const rmid = rm_target.rm_id;
                        this.add_defult_Target(new_target, rmid, new_target);
                    });
                }})
            }
            
            
        });
    }

    Editable(event, row, originalValue) {
        const rel_mgr_id = row.original.rm_id;
        const target = Number(event.target.value);
        const edited_target = { ...this.state.edited_target };
        edited_target[rel_mgr_id] = target;
        const editing = { ...this.state.editing, [rel_mgr_id]: true };
        if((target === 0 && (originalValue === 0 || !originalValue || originalValue === 'NA')) || (target === Number(originalValue))){
            delete edited_target[rel_mgr_id];
            delete editing[rel_mgr_id];
        }
        this.setState({ edited_target, editing });
    }                               

    handleEditValues = (rmid = null) => {
        if (typeof rmid === 'object'){
            rmid = null;
        }
        const commonState = { editable: true, save: true, edit: false };
        if (this.state.edit_cur_month) {
            this.setState(prevState => ({...commonState,edit_cur_values: true,single_edit: {...prevState.single_edit,...(rmid !==null && { [rmid]: true })
                }}),() => {
                this.setState(state => ({
                  columns: this.columndatas(this.state.header)
                }));
              });
              
        } else if (this.state.edit_next_month) {
            this.setState(prevState => ({...commonState,edit_next_values: true,single_edit: {...prevState.single_edit,...(rmid !==null && { [rmid]: true })
                }}), () => {
                this.setState(state => ({
                  columns: this.columndatas(this.state.header)
                }));
              });

        } else {
          this.setState(commonState);
        }
      };

    changeHandler = (e) => {		
        this.setState({ searchValue: e });
        const toFilter = e
        const filterfrom = this.state.row_datas
        const filteredData = filterfrom.filter(value => {
        return(
            value.rm_name !==null && value.rm_name.toLowerCase().includes(toFilter.toLowerCase())				
        )
        })
        this.setState(state=>state.copy = filteredData)		
    }

    cellEditable(row) {
        const { editable, show_target_text, edited_target, editing } = this.state;    
        const rmId = row.original.rm_id;
        const originalValue = show_target_text? row.original.next_targets : row.original.targetted;
        const currentValue = edited_target[rmId] !== undefined ? edited_target[rmId] : originalValue;
    
        const isEditing = editing && editing[rmId];
        const showCancelIcon = isEditing && Number(currentValue) !== Number(originalValue);
    
        if (editable) {
            if (Object.keys(this.state.single_edit).includes(rmId)) {
                return this.renderEditableCell(currentValue, showCancelIcon, rmId, originalValue, row);
            }
            else if (Object.keys(this.state.single_edit).length === 0){
                return this.renderEditableCell(currentValue, showCancelIcon, rmId, originalValue, row);
            }
            else{
                return this.show_icon(originalValue, rmId);
            }
        } else {
            return this.show_icon(originalValue, rmId);
        }
        
    }
    
    renderEditableCell(currentValue, showCancelIcon, rmId, originalValue,row) {
        return (
            <td>
                <input
                    type="number"
                    min="0"
                    pattern="\d*"
                    onKeyDown={(e) => !/[0-9]/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key) && e.preventDefault()}
                    style={{ width: '50%', backgroundColor: showCancelIcon ? '#ffc5c5' : '' }}
                    value={currentValue}
                    onChange={(e) => this.Editable(e, row, originalValue)}
                />
                {showCancelIcon && this.renderIcons(rmId, originalValue,row)}
            </td>
        );
    }
    
    renderIcons(rmId, originalValue,row) {
        return (
            <div>
                <BsXCircle
                    style={{ marginLeft: '5px', cursor: 'pointer', fontSize: '14px', color: '#f00000', marginTop: '3px' }}
                    onClick={() => this.handleRemove(rmId)}
                />
                <BsCheckCircle
                    style={{ marginLeft: '5px', cursor: 'pointer', fontSize: '14px', color: 'lime', marginTop: '3px' }}
                    onClick={() => this.handleSend(rmId, originalValue,row)}
                />
            </div>
        );
    }
    
    handleRemove(rmId) {
        const updatedEditedTarget = { ...this.state.edited_target };
        delete updatedEditedTarget[rmId];
    
        const updatedEditing = { ...this.state.editing };
        delete updatedEditing[rmId];

        const single_edit = { ...this.state.single_edit };
        const commonState = { editable: true, save: true};
        if (single_edit[rmId]) {
            delete single_edit[rmId];
            (() => {
                if (Object.entries(single_edit).length === 0) {
                    commonState.editable = false;
                    commonState.save = false;
                }
            })();
        }
    
        this.setState({edited_target: updatedEditedTarget,editing: updatedEditing,single_edit: single_edit,...commonState,loder:true},()=>this.setState({loder:false}));
    }
    
    async handleSend(rmId, originalValue,row) {
        const { show_target_text } = this.state;
        const currentValue = this.state.edited_target[rmId] !== undefined ? this.state.edited_target[rmId] : originalValue;
    
        if (show_target_text) {
            row.original.next_targets = currentValue;
        } else {
            row.original.targetted = currentValue;
        }
    
        try {
            const updatedEditedTarget = { ...this.state.edited_target };
            Object.keys(updatedEditedTarget).forEach(key => {
                if (key !== rmId) {
                    delete updatedEditedTarget[key];
                }
            });
    
            const dt = new Date(Date.now());
            const date = new Date(dt.getFullYear(), dt.getMonth(), 1);
            const cur_month = format(date, 'yyyy-MM-01');
            date.setMonth(date.getMonth() + 1);
            const month = this.state.edit_cur_values ? cur_month : format(date, 'yyyy-MM-01');
    
            await updateRMTarget({
                data: {target: updatedEditedTarget,target_month: month,month: cur_month
                }
            });
            const commonState = { editable: true, save: true};
            const EditedTarget = { ...this.state.edited_target };
            delete EditedTarget[rmId];
    
            const Editing = { ...this.state.editing };
            delete Editing[rmId];
    
            const single_edit = { ...this.state.single_edit };
            
            if (single_edit[rmId]) {
                delete single_edit[rmId];
                (() => {
                    if (Object.entries(single_edit).length === 0) {
                        commonState.editable = false;
                        commonState.save = false;
                    }
                })();
            }
    
            this.setState({edited_target: EditedTarget,editing: Editing,single_edit: single_edit,...commonState,loder:true},()=>this.setState({loder:false}));
        } catch (error) {
            alert("Error updating target:", error);
        }
    }
    
    

    updateTarget(row){
            if(Object.keys(this.state.next_targets).length == 0){
                return <form> <td><input type='number' min="0" pattern="\d*" style={{width:"50px"}} autoComplete = "off" defaultValue={this.state.assigned ? this.state.assigned[row.rm_id] :''} onKeyDown={(e) => !/[0-9]/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key) && e.preventDefault()} id={row.id}  onChange={(e) => this.addTarget(e,row)}></input></td></form>
            }else{
               
                return row.next_targets
            }
        }

    cancel_target = () => {
        const date = new Date()
        date.setMonth(date.getMonth() );
        const cur_month = format(date, 'yyyy-MM-01')
        const month = new Date(cur_month);
        const month_name = date.toLocaleString('en-US', {month: 'long'})
        this.setState({editable:false,save:false,loder: true,resp: false,editing: {},edited_target: {}},() => {
            const updatedColumns = this.columndatas(this.state.header);
            this.setState({ columns: updatedColumns,loder: false, resp: true,single_edit:{}});
        })
    }

    handleSave = (event) => {

        const dt = new Date(Date.now());
        const date = new Date(dt.getFullYear(), dt.getMonth(), 1)
        const cur_month = format(date, 'yyyy-MM-01')
        const next_month = date.setMonth(date.getMonth()+1)
        const month = this.state.edit_cur_values ? cur_month : format(date, 'yyyy-MM-01')
        this.setState({show_target_text: this.state.show_target_text,loder:true }, async () => {
            try {
                await updateRMTarget(this.req({
                    data: {
                        target: this.state.edited_target,
                        target_month: month,
                        month: cur_month
                    }
                }));
            } finally {
                this.handleView();
            }
        });
     }


     async drawTable(req_month) {
        this.setState({
            loder: true,
            resp: false,
            searchValue: '',
            editing: {},
            edited_target: {}
        });
    
        try {
            const response = await viewRMTarget(this.req({ month: req_month, view: 'next_month' }));
    
            if (!response) {
                this.setState({ loder: false, resp: true });
                return;
            }
    
            if (response.status === "success") {
                this.setState(state => ({columns: this.columndatas(response.data.headers)}));
    
                if (response.data.records_arr) {
                    this.setState({row_datas: response.data.records_arr,copy: response.data.records_arr,loder: false,resp: true,header: response.data.headers,single_edit:{},default_update:response.data.headers.default_update},()=>{
                        this.getPastTarget();
                        this.getNextTarget();
                    });
                    
                    
                }
            } else {
                this.setState({ loder: true, resp: true,single_edit:{} });
            }
    
            if ((Object.keys(this.state.next_targets).length !== 0) && !this.state.save && !this.state.edit_cur_month && !this.state.past_target) {
                this.setState({edit_next_month: true,show_target_text: true});
            }
        } catch (error) {
            this.setState({ loder: true, resp: true });
        }
    }
    

    myloader = (state,resp=false )=>{return(
        (state && resp)?
            <p className='text-white text-center mt-5'>
                No data Found
            </p> 
         :
         <div style={{marginTop:"15%"}} > <p className='text-light mt-3 text-center'>Loading...</p>
             <div className="product_takeup_loader"></div>
         </div>
         )}

    show_icon = (value, rmid) => (
        <td>
            <div style={{ width: '50%' }}>{value ? value : 0}</div>
            <FiEdit
            style={{ marginLeft: '10px', cursor: 'pointer' }}
            onClick={() => this.handleEditValues(Number (rmid))}
            />
        </td>
        );
        columndatas(data) {
            return [
              {
                Header: <p style={{ fontSize: '13px' }}>{data.rm_name}</p>,
                id: 'rm_name',
                accessor: (row) => row.rm_name,
                minWidth: 250,
                maxWidth: 375,
              },
              {
                Header: <p style={{ fontSize: '13px' }}>{data.month}</p>,
                id: 'targetted',
                Cell: ({ row }) =>
                  this.state.edit_cur_month && this.state.edit_duration
                    ? this.cellEditable(row)
                    : 
                    row.original.targetted,
                minWidth: 50,
                maxWidth: 200,
              },
              {
                Header: <p style={{ fontSize: '13px' }}>{data.month_accquired}</p>,
                id: 'month_accquired',
                accessor: (row) => row.month_accquired,
                minWidth: 50,
                maxWidth: 200,
              },
              {
                Header: <p style={{ fontSize: '13px' }}>{data.accquired}</p>,
                id: 'accquired',
                accessor: (row) => row.accquired,
                minWidth: 50,
                maxWidth: 200,
                sortMethod: (a, b) => parseInt(a) - parseInt(b),
              },
              {
                Header: <p style={{ fontSize: '13px' }}>{data.add_targets}</p>,
                id: 'next_targets',
                show: this.state.show_target_text,
                Cell: ({ row }) =>
                Object.keys(this.state.next_targets).length !== 0 && this.state.edit_next_month
                    ? this.cellEditable(row)
                    : this.updateTarget(row.original),
                minWidth: 50,
                maxWidth: 200,
              },
            ];
          }


          


    render() {
        
       
        return (
            <div>
                <div className='mb-3 mt-2'>
                    <h5 style={{ color: "white" }}>{this.props.title}</h5>
                </div>
                
                <div className='d-flex justify-content-between align-items-center' style={{padding:5}}>
                    <div className="row mb-5" >
                        <ul class="d-flex" >
                            <li className=" pr-1">
                                <Button onClick={this.showPastTarget}>View Targets</Button>
                            </li>
                            <li className=" pr-1">
                                <Button onClick={this.handleCurDate}>{this.state.cur_month_name}</Button>
                            </li>
                            {this.state.edit_role && this.state.next_month_flag && 
                            <li className=" pr-1">
                                {/* <Button disabled = {this.state.past_target} onClick={this.showAddTarget}>{this.state.next_month_name}</Button> */}
                                <Button onClick={this.showAddTarget}>{this.state.next_month_name}</Button>
                            </li>
                            }
                            {((this.state.edit)  || ((this.state.edit_cur_month || this.state.edit_next_month) && !this.state.save )) && (this.state.edit_duration || this.state.full_edit) && this.state.edit_role && 
                            <li className=" pr-1">
                                <Button onClick={this.handleEditValues}>Edit All</Button>
                            </li>
                            }
                            {Object.keys(this.state.edited_target).length === 0 && this.state.save &&
                            <li className=" pr-1">         
                                <Button  onClick={this.cancel_target}>cancel</Button>
                            </li>
                            }
                        </ul>
                    </div>
                    {Object.keys(this.state.single_edit).length === 0 &&
                        <div style={{position:'relative'}}>
                            <i class="fa-solid fa-magnifying-glass search_pos"></i>
                            <input placeholder='Search RM Name' className='search_posInput' value={this.state.searchValue}  onChange={(e)=>this.changeHandler(e.target.value)}></input>
                            {/* <button className='btn-std btn btn-primary' onClick={()=>this.resetHandler()}>Reset</button> */}
                       </div>
                    }
                </div>
                {this.state.past_target &&
                    <div className='d-flex mb-3'>

                        <Assign id="past_month" data={this.state.past_month} cur_date={this.state.cur_month} onComponentChange={this.handleComponentChange} />

                        <div className='col-mt-2  mt-4'>
                            <Button onClick={this.handleSearch} >Show</Button>
                        </div>

                    </div>
                }
                
                <div className='text-center'>
                    <h5 style={{ color: "white" }}>{this.state.view_month_name}</h5>
                </div>
                
                
                <div className="card-body m-auto" style={{width: '70%'}}>
                    {/* <CommonReactTable showPag={true} defaultPageSize={50} minRow={0} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} resizable={false} defaultSorted={[{ id: "month_accquired", asc: true }]} searchData={[{view_month_name:this.state.view_month_name,title:'RM Targets'}]} /> */}
                    { !this.state.loder ?
                    <>
                    <BasicReactTable pagination={true} FName = {`Monthly Target For RMs (${moment().format('YYYY-MM-DD')})`} default_page_size={50} row_data={this.state.copy !== undefined ? this.state.copy : []} column_data={this.state.columns} searchData={[{view_month_name:this.state.view_month_name,title:'RM Targets'}]}  csv_file={true} />

                    {this.state.add_targets && Object.keys(this.state.next_targets).length == 0 &&
                        <div className='col-md-12 text-center mt-5'>
                        <Button onClick={this.handleSubmit}>Submit</Button>
                        </div>
                    }
                    {this.state.save &&
                        <div className='col-12 text-center mt-5'>       
                            <Button disabled = {Object.keys(this.state.edited_target).length === 0} onClick={this.handleSave}>Save</Button>
                        </div>
                    }
                    </>
                    : this.myloader(this.state.loder,this.state.resp)
                    }
                </div>

                <div className='mt-5 mb-5'>
                    <p style={{color : "white",textAlign:'center'}} >
                        NOTE ** Please ensure that you have Assigned Targets for Relationship Managers Before Start of the Next Month
                    </p>
                </div>
   
            </div>

        )
    }



}
export default Assign_container;