import React from "react";
import FlowComponent from "../../common/component/core/FlowComponent";
import {
  AiOutlineIdcard,
  AiOutlineMobile,
  AiFillShop,
  AiFillEdit,
} from "react-icons/ai";
import {
  MdBusiness,
  MdPerson,
  MdAccountCircle,
  MdImage,
  MdPersonOutline,
  MdPhoto,
  MdLocationPin,
  MdArrowDropDown,
} from "react-icons/md";
import {
  BiRefresh,
  BiPhoneOff,
  BiPhoneCall,
  BiXCircle,
  BiPhotoAlbum,
  BiEdit,
  BiMap,
  BiCheck,
} from "react-icons/bi";
import { GoVerified } from "react-icons/go";
import { FaHandsHelping } from "react-icons/fa";
import {
  HiOutlineDocumentAdd,
  HiPhoneOutgoing,
  HiArrowCircleRight,
  HiArrowCircleLeft,
} from "react-icons/hi";
import { ImCircleDown, ImCircleUp } from "react-icons/im";
import { CgNotes } from "react-icons/cg";
import { BsClipboardData } from "react-icons/bs";
import FileUpload from "../../common/component/FileUpload";
import Alert from "react-bootstrap/Alert";
import { get, Config } from "../../../helpers/storage_helper";
import {
  viewLead,
  UpdateFlags,
  addComments,
  auditKycVerification,
  auditKyRetrievals,
  auditKycFileUpload,
  submitCallLog,
  RejectCallLog,
  auditkycModify,
  updateAccPrvdrCode,
} from "../../../actions/lead_actions";
import {
  capitalize_first_letter,
  get_dir_path,
} from "../../../helpers/common_helper";
import { approveKYC, updateLeadStatus } from "../../../actions/kyc_actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import isValid from "date-fns/fp/isValid";
import { dd_value } from "../../../actions/common_actions";
import GoogleMapSetup from "../../../helpers/googleMap";
import {
  Homeacc_prvdrLogoselector,
  convert_sec_to_min_sec,
} from "../../../helpers/common_helper";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";

class AuditKycVerificationSections extends FlowComponent {
  constructor() {
    super();
  }

  verify_button_status() {
    let data = this.props.verification_datas;
    if (data) {
      if (data.audit_kyc_wizard_menus.mobile_numbers) {
        return (
          this.props.verification_datas.cust_mobile_verify &&
          this.props.verification_datas.addl_mobile_verify
        );
      }
      if (data.audit_kyc_wizard_menus.agreements) {
        let flag_stats =
          this.check_flags(this.props.audit_kyc[data.active_key]) &&
          this.is_name_retrieved(
            data.cust_reg_json.agreements,
            data.active_key
          );
        return flag_stats;
      }
      if (data.audit_kyc_wizard_menus.biz_accounts) {
        let flag_stats =
          this.check_flags(this.props.audit_kyc[data.active_key]) &&
          this.is_name_retrieved(
            data.cust_reg_json.biz_accounts.accounts,
            data.active_key
          );
        return flag_stats;
      } else {
        let flag_status = this.check_flags(
          this.props.audit_kyc[data.active_key]
        );
        if (
          data.responseData.type == "re_kyc" &&
          (data.active_key == "id_proof" || data.active_key == "gps_address")
        ) {
          return flag_status && data.verify_bttn_status;
        } else {
          return flag_status;
        }
      }
    }
  }

  check_flags(flags) {
    if (flags && Object.keys(flags).length > 0) {
      for (const value of Object.values(flags)) {
        if (!value) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  is_name_retrieved(data, type) {
    let flag_condition = false;
    data.forEach((item, idx) => {
      let mis_match_reason =
        type === "agreements"
          ? "agreements_witness_mismatch_reason_"
          : "account_holder_name_mismatch_reason_";

      let reason =
        item.hasOwnProperty("name_match") && !item.name_match
          ? this.props.verification_datas[`${mis_match_reason}${idx}`]
          : true;

      if (item.hasOwnProperty("name_match") && reason) {
        flag_condition = true;
      }
      if (type == "biz_accounts") {
        let doc_status = item.hasOwnProperty("document_status")
          ? item.document_status === "insufficient_documents"
            ? item?.missing_documents?.length > 0
            : item.document_status === "sufficient_documents"
          : false;
        console.log("doc_status", doc_status);
        flag_condition = flag_condition && doc_status;
      }
    });

    return flag_condition;
  }

  render() {
    const is_verify =
      this.props.verification_datas &&
      this.props.verification_datas.active_key == "agreements"
        ? this.props.verification_datas.active_section[0].verify
        : this.props.verification_datas &&
          this.props.verification_datas.active_section &&
          this.props.verification_datas.active_section.verify;
    return (
      <>
        {this.props.verification_datas &&
          this.props.verification_datas.active_section && (
            <div
              className={`${
                (this.props.verification_datas.responseData.audited_by ==
                  get("user_id") ||
                  this.props.verification_datas.responseData.audited_by ==
                    null) &&
                this.props.verification_datas.active_section.pending_with !=
                  "rm"
                  ? ""
                  : "disable_div"
              }`}
            >
              {/* {this.props.verification_datas.audit_kyc_wizard_menus.id_proof && */}
              <>
                {this.props.verification_datas.active_section &&
                  !this.props.verification_datas.active_section.to_reassign && (
                    <>
                      {is_verify ? (
                        <div className="d-flex  justify-content-center mt-4">
                          <button
                            type="button"
                            class="btn btn-danger z_index"
                            onClick={() => this.props.unverify_handler()}
                          >
                            {" "}
                            <b>Unverify</b>
                          </button>
                        </div>
                      ) : (
                        <div>
                          <div className="d-flex  justify-content-center mt-4">
                            <button
                              type="button"
                              class={`${
                                this.verify_button_status()
                                  ? "btn btn-success"
                                  : "btn btn-secondary"
                              }`}
                              disabled={
                                this.verify_button_status() ? false : true
                              }
                              onClick={() => {
                                this.props.verification_datas
                                  .audit_kyc_wizard_menus.agreements
                                  ? this.props.verify_handler(
                                      "mobile_num_verify",
                                      null,
                                      null,
                                      "agreements_verify"
                                    )
                                  : this.props.verification_datas
                                      .audit_kyc_wizard_menus.biz_accounts
                                  ? this.props.verify_handler(
                                      "acc_owner",
                                      null,
                                      null,
                                      this.props.verification_datas
                                        .cust_reg_json
                                    )
                                  : this.props.verify_handler();
                              }}
                            >
                              {" "}
                              <b>Verify</b>
                            </button>
                          </div>

                          <div className="d-flex  justify-content-center z_index">
                            <p
                              className="text-white no-margin"
                              style={{ fontSize: "15px" }}
                            >
                              {" "}
                              Check all the items in the checklist to verify
                            </p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
              </>
            </div>
          )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    audit_kyc: state.common_slice,
  };
};

const mapDispatchToProps = {
  // setValuesIntoRedux
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditKycVerificationSections);