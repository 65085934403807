import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, FormLabel } from "@mui/material";
import RHFAutocomplete, { RHFAutocompleteProps } from "./rhfAutoComplete";

export default function FixedLabelRhfAutoComplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  name,
  label,
  type,
  variant,
  required,
  helperText,
  placeholder,
  customOnchangeCb,
  ...other
}: Omit<
  RHFAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  "renderInput"
>) {
  return (
    <Box
      sx={{
        "& .MuiFormHelperText-root": {
          fontSize: "12px",
        },
      }}
    >
      <FormLabel
        required={required}
        sx={{
          "& .MuiFormLabel-asterisk": {
            // adds style to *
            color: "#DC3545",
            fontSize: "18px",
          },
          // adds style to label
          fontSize: "10px",
          margin: 0,
          color: "#b0b7cd!important",
          textTransform: "uppercase",
        }}
      >
        {other.loading ? "Loading..." : label}
      </FormLabel>
      {/* autocomplete field UI */}
      <RHFAutocomplete
        name={name}
        type={type}
        variant={variant}
        helperText={helperText}
        placeholder={placeholder}
        popupIcon={
          <KeyboardArrowDownIcon sx={{ width: "18px", height: "18px" }} />
        }
        slotProps={{
          paper: {
            sx: {
              background: "#212540!important",
              border: (theme) =>
                // @ts-ignore
                `1px solid ${theme.palette.custom_colors.grey}!important`,
              "& li": {
                p: "5px!important",
                fontSize: "0.75rem",
              },
              "& .MuiAutocomplete-option.Mui-focused": {
                backgroundColor: (theme) =>
                  // @ts-ignore
                  `${theme.palette.custom_colors.secondary_500}!important`,
              },
              "& .MuiAutocomplete-listbox": {
                borderRadius: "0px",
              },
            },
          },
        }}
        customOnchangeCb={customOnchangeCb}
        {...other}
      />
    </Box>
  );
}
