import { useTheme } from "@mui/material";
import { ApexOptions } from "apexcharts";

type Props = {
  yAxisTitle: string;
  formatterCbY?: Function;
  categories: string[];
  colors: string[];
};

const useGetAreaChart = ({
  yAxisTitle,
  formatterCbY,
  categories,
  colors,
}: Props) => {
  // hook
  const theme = useTheme();

  const options: ApexOptions = {
    chart: {
      height: 350,
      toolbar: {
        show: true,
      },
    },
    // legend: {
    //   show: true,
    //   showForSingleSeries: true,
    // },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      labels: {
        style: {
          colors: theme.palette.text.primary,
        },
      },
      categories,
    },
    yaxis: {
      title: {
        text: yAxisTitle,
        style: {
          color: theme.palette.text.primary,
        },
      },
      labels: {
        style: {
          colors: theme.palette.text.primary,
        },
        ...(formatterCbY && { formatter: (val) => formatterCbY(val) }),
      },
    },
    colors,
  };

  return {
    options,
  };
};

export default useGetAreaChart;
