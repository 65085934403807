import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Checkbox, FormControlLabel, Grid, useTheme } from "@mui/material";
import AddressKycCommon from "../component/AddressKycCommon"; // Import your rekycVerify method
import { Config, check_priv, get } from "../../../helpers/storage_helper";
import { rekycVerify } from "../../../actions/lead_actions";
import GoogleMapSetup from "../../../helpers/googleMap";
import TextField from "../../CommonComponent/field/TextField";
import { dd_value } from "../../../actions/common_actions";
import { req } from "../../../helpers/common_helper";
import withRouter from "../../../Router/Withrouter";
import { Link, useParams } from "react-router-dom";
import { FaPhoneAlt } from "react-icons/fa";
import { setValuesIntoRedux } from "../../../Redux/Slices/CommonSlice";
import Softphone from "../../cs_management/container/cs_soft_phone";
import { getCustDetails } from "../../../actions/borrower_actions";
import { string } from "yup";
import { BodyVarientTwoTypo, HeaderVarientH4Typo } from "../../CommonComponent/typography";

interface RekycGPSAddressProps {
  gps_addr: any;
  checkbox_verification: (checked: boolean, checkboxId: string) => void;
  view_lead: () => void;
}
interface RouteParams {
  [key: string]: string | undefined; // Fix: index signature
}
const RekycGPSAddress: React.FC<RekycGPSAddressProps> = ({
  gps_addr,
  checkbox_verification,
  view_lead,
}) => {
  const addl_addr_field = [
    { label: "verified by", key: "verification_method" },
  ];

  const dispatch = useDispatch()
  const audit_kyc = useSelector((state: any) => state.common_slice);
  const softPhoneState = useSelector((state:any) => state.cs_soft_phone)
  const theme = useTheme();

  const [leadNumVerifyCall, setLeadNumVerifyCall] = useState<boolean>(false)

  const rekyc_verify_handler = (verify_request_key: string) => {
    const request = {
      lead_id: lead_id.id,
      path: verify_request_key,
    };
    // @ts-ignore
    rekycVerify(req(request)).then((response) => {
      if (response && response.status === "success") {
        view_lead();
      }
      if (!response) {
        return;
      }
    });
  };
  useEffect(()=>{
    if(check_priv('lead', 'verify_lead_num_call') && get("market").country_code == 'UGA'){
      setLeadNumVerifyCall(true)
    }
  },[])


  const { active_section, responseData } = gps_addr;
  const userId = get("user_id");

  const check_box_disable_cond =
      active_section?.verify ||
      active_section.to_reassign === true ||
      responseData.audited_by !== userId ||
      responseData.audited_by == null ||
      active_section.pending_with == "rm";

  const lead_id = useParams<RouteParams>();
  const addl_fields = (fields: { label: string; key: string}[], data: any, call ?: boolean) => {
    return (
      <Box sx={{ border: "1px solid  #2FB8D8", my: 2, py: 2, px: 5 }}>
        {fields?.length > 0 &&
          fields.map((_item, idx) => {
            return (
                <Grid container key={idx}>
                  <Grid item xs={5}>
                    <BodyVarientTwoTypo 
                    text={_item.label}
                    overRideStyles={{
                      color:theme.palette.text.primary,
                      textTransform:"uppercase"
                    }}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    {
                    (call && data?.[_item.key]?.value == 'reference' 
                      ? <Link className="audit_cust_url" target="blank" to={`/borrower/indiv/view/${data.ref_cust_id.value}`} >{data.ref_cust_id.value}</Link>
                      :<HeaderVarientH4Typo
                          text={String(data?.[_item.key]?.value ? (call && data[_item.key].value == 'others' ? data.ref_mobile_num.value : dd_value(data[_item.key].value)) : '-')}
                          overRideStyles={{
                            color: theme.palette.text.primary,
                            textTransform: "capitalize",
                          }}
                        />)
                    }
                  </Grid>
                  <Grid item xs={2}>
                    {leadNumVerifyCall && call && data?.[_item.key]?.value && (data[_item.key].value == 'others' || data[_item.key].value == 'reference') &&
                      <div id="dial-icon" className={`call-icon lead_call_icon ${softPhoneState.verification_call || check_box_disable_cond ? 'disable_div' : ''}`}
                      onClick={()=>handleCall(data.ref_mobile_num.value, data[_item.key].value, data[_item.key].value == 'reference' ? data.ref_cust_id.value : lead_id)}>
                        <FaPhoneAlt style={{color:"white"}}/>
                      </div>
                    }
                  </Grid>
                </Grid>
            );
          })}
      </Box>
    );
  };

  const get_cust_details = async(cust_id : string) => {
    let cust_name = null
    let request = {cust_id : cust_id} 

    // @ts-ignore
    await getCustDetails(req(request))
    .then((response) => {
      if(response?.data?.cust_name){
        cust_name =  response.data.cust_name
      }
      }
    )
    return cust_name
  }

  const handleCall = async(mobile_num : string, type : string, entity_id : string) => {

    if(type == 'reference'){
      let cust_name = await get_cust_details(entity_id)
      dispatch(setValuesIntoRedux({screen_from:"verification_call", cust_id :entity_id, outgoing_call_data : {cust_id : entity_id, cust_mobile_num : mobile_num, cust_name}, lead_details : {lead_id : entity_id}, verification_call : true}))
    }else{
      dispatch(setValuesIntoRedux({screen_from:"verification_call", lead_id :entity_id, outgoing_call_data : {lead_id : entity_id, lead_mob_num : mobile_num}, lead_details : {lead_id : entity_id, lead_mob_num : mobile_num}, verification_call : true}))
    }
    
  }

  const check_box_field = (
    checked_condition: boolean,
    checkbox_id: string,
    label?: string | null,
    ref_cust_id?: string
  ) => {
    

    return (
      <Box>
        <FormControlLabel
          sx={{
            color: "white", fontSize: "20px",mt:1,mb:2
          }}

          control={
            <Checkbox
              disabled={check_box_disable_cond  }
              color="secondary"
              sx={{ fontSize: "20px" }}
              checked={checked_condition} // Pass dynamic checked value
              onChange={(e) =>
                checkbox_verification(e.target.checked, checkbox_id)
              }
            />
          }
          label={
            ref_cust_id && checkbox_id != "gps_address_match" ? (
              <span>
                {label}  <Link className="audit_cust_url" to={`/borrower/indiv/view/${ref_cust_id}`} >{ref_cust_id}</Link>
              </span>
            ) : label
          }
        />
      </Box>
    );
  };

  const getOwnerAddressText = (visitType: string) => { // reference, others, lcletter, homevisit
    const ref_mob_num = cust_reg_json?.gps_address?.owner_address?.ref_mobile_num?.value;
    const baseText = `${Config('cont_for_kyc_addr')[visit_type]}`
    if(visitType === 'reference') return `${baseText}` // reference type no need to concatinate the ref_cust_id because that was done inside check_box_field method
    else if(visitType === 'others') return `${baseText} ${ref_mob_num}` // others type will have the mbl number need to concatinate that with the base text
    return baseText // for other cases lcletter and homevisit no need to do anything just basetext is enough
  }

  const { cust_reg_json } = gps_addr;
  const visit_type = cust_reg_json?.gps_address?.owner_address?.verification_method?.value;
  // const ref_mob_num = cust_reg_json?.gps_address?.owner_address?.ref_mobile_num?.value;

  const ref_cust_id = cust_reg_json?.gps_address?.owner_address?.ref_cust_id?.value;
  let owner_addr_text = getOwnerAddressText(visit_type)
  // const valuesToAppend = [ref_mob_num];
  // valuesToAppend.forEach((value) => {
  //   if (value) {
  //     owner_addr_text += " " + value;
  //   }
  // });

  return (
    <div>
      {gps_addr.audit_kyc_wizard_menus?.gps_address && (
        <div className="row no-margin justify-content-center">
          {gps_addr.View_lead_section_loader ? (
            <div className="d-flex justify-content-center">
            </div>
          ) : (
            <>
              <div
                className="col-lg-12 py-3 mt-5"
                style={{ background: "#01062c" }}
              >
                <h2 className="text-white text-center">
                  <b>
                    {gps_addr.cust_reg_json?.same_as_owner_person ? "2" : "3"} -
                    Map Location Verification
                  </b>
                </h2>
                <h2 className="d-flex mt-1 text-center justify-content-center align-items-center">
                  <img
                    src="/img/pending_with_rm.png"
                    height={"50"}
                    alt="pending with rm"
                  />{" "}
                  <b
                    className="ml-2 align-self-center"
                    style={{ color: "#F3EA0B" }}
                  >
                    Pending with{" "}
                    {gps_addr.active_section?.pending_with === "rm"
                      ? "RM"
                      : "YOU"}
                  </b>
                </h2>
              </div>
              <div className="col-lg-10  px-5">
                <div className="third_party_bg p-5">
                  <div
                    className="col-md-12 w-100 p-0"
                    style={{ margin: "auto" }}
                  >
                    {/* @ts-ignore */}
                    <GoogleMapSetup
                      current_coordinates={
                        gps_addr.new_cust_reg_json?.gps_address?.gps?.value
                      }
                      coordinates={
                        gps_addr.old_cust_reg_json?.gps_address?.gps?.value
                      }
                      height={"400px"}
                      color={true}
                    />
                  </div>
                  <p
                    className="no-margin text-white pt-3 pb-3"
                    style={{ fontSize: "15px" }}
                  >
                    <b style={{ color: "red" }}>Note :</b> Zoom In/Zoom Out to
                    verify if the GPS location pointed by the RM matches the
                    business address captured.
                  </p>

                  {gps_addr.cust_reg_json?.gps_address?.biz_address && (
                    <div className="col-lg-12 mt-4 m-auto ">

                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <AddressKycCommon
                            data={
                              gps_addr.old_cust_reg_json.gps_address.biz_address
                            }
                            title="Business Address (As per KYC)"
                            usedFor="re-kyc"
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <AddressKycCommon
                            data={
                              gps_addr.cust_reg_json.gps_address.biz_address
                            }
                            title="Business Address (As per Re-KYC)"
                            addr_update_path="gps_address.biz_address"
                            usedFor="re-kyc"
                            verify_handler={(req_path) =>
                              rekyc_verify_handler(req_path)
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        {check_box_field(
                          audit_kyc?.gps_address?.gps_address_match,
                          "gps_address_match",
                          " The location in the Business Address matches with the gps location",
                        )}
                      </Grid>
                    </div>
                  )}
                  {gps_addr.cust_reg_json?.gps_address?.owner_address && (
                    <div className="col-lg-12 mt-4 m-auto ">
                      {/* address re-kyc */}
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <AddressKycCommon
                            data={
                              gps_addr.old_cust_reg_json.gps_address.owner_address
                            }
                            title="Current Residential Address             (As per KYC)"
                            usedFor="re-kyc"
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <AddressKycCommon
                            data={
                              gps_addr.cust_reg_json.gps_address.owner_address
                            }
                            title="Current Residential Address (As per Re-KYC)"
                            addr_update_path="gps_address.owner_address"
                            usedFor="re-kyc"
                            verify_handler={(verify_request_key) =>
                              rekyc_verify_handler(verify_request_key)
                            }
                          />
                          {/* {addl_fields(
                            addl_addr_field,
                            gps_addr.cust_reg_json.gps_address.owner_address
                          )} */}
                        </Grid>
                      </Grid>

                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          {addl_fields(
                            addl_addr_field,
                            gps_addr.old_cust_reg_json.gps_address.owner_address,
                          )}
                        </Grid>

                        <Grid item xs={12} lg={6}>
                          {addl_fields(
                            addl_addr_field,
                            gps_addr.cust_reg_json.gps_address.owner_address,
                            true
                          )}
                        </Grid>
                      </Grid>
                      {owner_addr_text &&
                      <Grid container>

                        {check_box_field(
                          audit_kyc?.gps_address?.confirm_cur_res_address,
                          "confirm_cur_res_address",
                          owner_addr_text,
                          ref_cust_id
                        )}
                      </Grid>}
                    </div>
                  )}
                  {gps_addr.cust_reg_json?.gps_address?.permanent_res_addr && (
                    <div className="col-lg-12  m-auto  ">
                      {/* address re-kyc */}
                      <Grid container spacing={1}>
                        <Grid item xs={12} lg={6}>
                          <AddressKycCommon
                            data={
                              gps_addr.old_cust_reg_json.gps_address
                                .permanent_res_addr
                            }
                            title="Permanent Address (As per KYC)"
                            usedFor="re-kyc"
                          />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <AddressKycCommon
                            data={
                              gps_addr.cust_reg_json.gps_address
                                .permanent_res_addr
                            }
                            addr_update_path="gps_address.permanent_res_addr"
                            title="Permanent Address (As per Re-KYC)"
                            usedFor="re-kyc"
                            verify_handler={(req_path) =>
                              rekyc_verify_handler(req_path)
                            }
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {
        softPhoneState.verification_call && 
        <Softphone screen_from ={"verification_call"} is_direct_outgoing = {true} lead_id={lead_id} outgoing_call_data = {softPhoneState.outgoing_call_data}/>
      }
    </div>
  );
};

export default RekycGPSAddress;
