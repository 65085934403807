import {
  MADAGASCAR,
  NO_MARKET_FOUND,
  RWANDA,
  UGANDA,
} from "../../../../consts";
import { get } from "../../../../helpers/storage_helper";
import BorrowerCommonAddressFormInitValGetContainerForMadagascar from "./initValGetContainer/BorrowerCommonAddressFormInitValGetContainerForMadagascar";
import BorrowerCommonAddressFormInitValGetContainerForRwa from "./initValGetContainer/BorrowerCommonAddressFormInitValGetContainerForRwa";
import BorrowerCommonAddressFormInitValGetContainerForUga from "./initValGetContainer/BorrowerCommonAddressFormInitValGetContainerForUga";

/**
 *
 * @param param0 title the header of the view, prop to children
 * @param param1 address_obj the address record, prop to children
 * @param param2 entity used to check the user privileage to show the edit btn, prop to children
 * @param param3 cust_id need to send for the update api, prop to children
 * @param param4 address_view_for the current res address and biz address have the location pick option, to show or hide the location field this field is used, prop to children
 * @returns form UI for the address record
 */
const BorrowerAddressEditContainer = ({
  title,
  address_obj,
  entity,
  cust_id,
  address_view_for,
}: {
  title: string;
  address_obj: Record<string, string>;
  entity: string;
  cust_id: string;
  address_view_for: "CUR_RES_ADD" | "PER_ADD" | "BIZ_ADD";
}) => {
  const market = get("market");

  if (![RWANDA, UGANDA, MADAGASCAR].includes(market.country_code))
    return <>{NO_MARKET_FOUND}</>;

  return (
    <>
      {market.country_code === RWANDA && (
        <BorrowerCommonAddressFormInitValGetContainerForRwa
          title={title}
          addressObj={address_obj}
          entity={entity}
          custId={cust_id}
          addressViewFor={address_view_for}
        />
      )}
      {market.country_code === UGANDA && (
        <BorrowerCommonAddressFormInitValGetContainerForUga
          title={title}
          addressObj={address_obj}
          entity={entity}
          custId={cust_id}
          addressViewFor={address_view_for}
        />
      )}
      {market.country_code === MADAGASCAR && (
        <BorrowerCommonAddressFormInitValGetContainerForMadagascar
          title={title}
          addressObj={address_obj}
          entity={entity}
          custId={cust_id}
          addressViewFor={address_view_for}
        />
      )}
    </>
  );
};

export default BorrowerAddressEditContainer;
