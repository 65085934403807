import React from 'react';
import FlowComponent from '../../common/component/core/FlowComponent';
import {getAddrConfig} from '../../../actions/common_actions';
import {LOADING_OPT, LOADING_MSG, NO_COUNTRY_MSG} from '../../../constants';
import {Edit} from '../../common/partial';
import { Button } from 'react-bootstrap'
import { check_priv } from '../../../helpers/storage_helper';


class AddressInfo extends FlowComponent {
    constructor() {
      super();
      this.mounted = 0;
      
      this.success = this.success.bind(this);
      this.process = this.process.bind(this);
    }

  state = {
    comp_name: "address",
     addr_config: [],
     borrower_country:"disable_div"

  }

  componentWillMount() {
     super.componentWillMount();
     this.setOptionsListToStateFromAppApi("common","country");
  }

  componentDidMount() {
    if(this.props.mode == "view"){
      this.props.data["country_code"] = this.country_code; 
      
      this.loadAddressUI(this.props.data.country_code);
      this.addr_country_code = this.props.data.country_code;
     }else{
      this.loadAddressUI(this.country_code);
     }
  }

     loadAddressUI(country_code){                                                                                                                                                                                                                                                                                    
       if(country_code === undefined) {
        this.setState({addr_config: [], loading_addr: NO_COUNTRY_MSG});
        return
       }
       this.setState({addr_config: [], loading_addr: LOADING_MSG});
       const status = "enabled";
        getAddrConfig(this.req({country_code, status}))
           .then((response) => {
          if(!response){return };
              this.setState({loading_addr: null});
              if(response.status == "success" && response.data.addr_config.length > 0){
                if(country_code=='UGA'){
                  var territoryField = {
                    field_num: "field_1",
                    field_code: "territory",
                    field_name: "Territory",
                    field_type: "select",
                    validation_rules: "required"
                  };
                  var updatedConfig = response.data.addr_config.slice();
                  updatedConfig.splice(6, 0, territoryField); 
                  this.setState({addr_config: updatedConfig});
                  this.selects = this.getAllSelects( updatedConfig, this.props.mode);
                }     
                else{
                  this.setState({addr_config: response.data.addr_config});
                  this.selects = this.getAllSelects(response.data.addr_config, this.props.mode);
                }
                      
                      if(this.props.mode == "view" || this.props.mode == "create"){
                          
                          this.fillAllSelects(this.selects, country_code, status);
                      }else{
                          const field_code = response.data.addr_config[0].field_code;
                          this.setState({[field_code+"_dd"]: LOADING_OPT});
                          this.setOptionsListToStateFromAppApi("common","dropdown",this.req({country_code, status, field_code}), ["data_code", "data_value"], field_code);
                      }     
              }else{
                      alert("Please ensure address form is configured correctly for " + country_code);
              }         
            }
            );   
  }


  createUI(change = null){
  
     var all_items = this.state.addr_config.map((field, i) => {

    var element = null;
    if(field.field_code == "gps"){
      element =  this.createGps(field.field_code, i);
    }
    if(field.field_type == "text"){ 
      element =  this.createText(field.field_code, i);

    }else if(field.field_type == "select"){ 

      element =  this.createSelect(field.field_code, i);

    }

    return (
      <div className="col-md-3">
        <span className='viewCus_label'>{field.field_name}</span>
        {this.getMandatoryTag(field.field_type, field.validation_rules)}
        {element}
      </div>
  );
         }
       );

      all_items.push(
                    <div className="col-md-3">
                      <button id='gps' type="button" className="btn-sm btn-light" onClick={this.locateMe}>Locate Me</button>
                        {this.state.gps_error &&
                          <p>{this.state.gps_error}</p>}
                      </div>
                );
     return(
      <div>
      <div className="row no-margin align-items-end labelTopgap">
     {all_items.shift()}
     {all_items.shift()}
     {all_items.shift()}
     {all_items.shift()}
     </div>
      <div className="row no-margin align-items-end labelTopgap">
     {all_items.shift()}
     {all_items.shift()}
     {all_items.shift()}
     {all_items.shift()}
     </div>
      <div className="row no-margin align-items-center labelTopgap" style={{paddingBottom: 23}}>
     {all_items.shift()}
     {all_items.shift()}
     {all_items.shift()}
     {all_items.shift()}
     </div>
      
     </div>
     );
       
    }

 
  createText(id, i){
    return (  
       <input id={id} value={this.props.data[id]}  onChange={this.handleChange.bind(this )} className="form-control" type="text" required="required"/>
     );
  }

   createGps(id, i){
    return (  
      <>
        {this.state.loading   && <p>{this.state.loading}</p> }
            {this.state.gps_value ? (
            <p><b><span>Lat, Lon: {this.state.gps_value}</span></b></p>
            ) : 
          <p><b><span>Lat, Lon: {this.props.data[id]}</span></b></p>
          }
        </>
     );
  }

  createSelect(id,i){
    return(
      <div>
        <select id={id} value={this.props.data[id]} onChange={this.loadChild.bind(this)} className="form-control" type="text" required="required">
            {this.state[id+"_dd"]}
        </select>
        </div>
      );
  }

  getMandatoryTag(field_type, validation_rules){

      if (field_type === "select" || validation_rules === "required"){
      return <font className="text-danger">   *  </font>;
      }
  }

  fillAllSelects(selects, country_code, status){
      var prevItem = null
      selects.forEach((item) => {
       const field_code = item.field_code;
        var parent_data_code, req = null;

        //if(prevItem && field_code != 'location'){
        if(prevItem && ['district', 'country'].includes(field_code)){
          parent_data_code = this.props.data[prevItem.field_code];
          req = {country_code, status, field_code, parent_data_code};
        }else{
          req = {country_code, status, field_code};
        }

        this.setOptionsListToStateFromAppApi("common" , "dropdown", this.req(req) , ["data_code", "data_value"], item.field_code);  
         prevItem = item;
         
     });
  }

  getAllSelects(addr_config, mode){
      var selects = [];
      addr_config.forEach((item) => {
         if (item.field_type === "select") 
         {
            selects.push(item);
            const field_code = item.field_code;
             var def_option;
             if(mode == "view"){
                def_option = LOADING_OPT
             }else{
                def_option = [];
             }

             this.setState({[field_code+"_dd"]: def_option});
         }
    });
     
  return selects;
  }

  loadChild(event){
    this.handleChange(event);
    const changed_field_code = event.target.id;
    this.getChildFieldCode(changed_field_code);
    const field_code  = this.child_field_code;
    if(field_code !=null){
        this.setState({[field_code+"_dd"]: LOADING_OPT});
        //const country_code = this.addr_country_code;
        const status = "enabled";
        const parent_data_code = event.target.value;
        const country_code = this.country_code;
        const req = {country_code, status, field_code, parent_data_code};
        this.setOptionsListToStateFromAppApi("common","dropdown", this.req(req) , ["data_code", "data_value"], field_code);

    }
  }

  getChildFieldCode(changed_field_code){
          this.state.addr_config
              .forEach((item) => {
                 if (item.field_code === changed_field_code) 
                  {
                    this.child_field_code = item.child_field_code;
                  }
              });
  }
  handleCountryChange = (event) => {
    this.handleReset(); 
    this.addr_country_code = event.target.value;
    this.props.data["country_code"] = event.target.value;
    this.loadAddressUI(event.target.value);
    this.handleChange(event);
  }
  // locateMe1 = (event) =>
  // {
  //     this.setState({
  //     gps_lat:  7.45454544234,
  //     gps_lon:  7.45454544234,
  //     gps: "7.45454544234, 7.45454544234"
  //     });
  //       event.target.id = "gps";
  //       event.target.value = "7.45454544234, 7.45454544234";
  //       this.handleChange(event);
     
  // }


process = (gps_error, gps_lat, gps_lon) => {
        
         var gps_lat, gps_lon,gps_error,gps_value = null;
        gps_value = gps_lat +  ", "+ gps_lon;
          this.setState({gps_error: false,
                        gps_lat,
                        gps_lon,
                        gps_value
                       });
          var event = {target: {id: 'test', value:gps_value}}
         
          //this.handleChange(event);
        }

    success = (position) => {
      const gps_error = false
      const gps_lat = position.coords.latitude;
      const gps_lon = position.coords.longitude;
      //this.process(gps_error, gps_lat, gps_lon)
      var gps_value = gps_lat +  ", "+ gps_lon;
      this.setState({gps_error,
                    gps_lat,
                    gps_lon,
                    gps_value,loading:null
                   });
      var event = {target: {id: 'gps', value:gps_value}}
      this.handleChange(event);
    }  

    error = (event) => {
      var gps_error = "Unable to retrieve your location";
      this.setState({gps_error, loading:null});
    }


  locateMe = (event) =>
  {

    if(navigator.geolocation)
    {
      navigator.geolocation.getCurrentPosition(this.success,this.error, 
        {enableHighAccuracy: true,  timeout: 50000});
      this.setState({loading: "Finding your location..."});
     }else{
        this.setState({gps_error : "Geolocation is not supported by your browser"});  
      }

  }
  render(){

    return (
            <div id="" 
              // className='no-padding-input' // padding enabled, select and input inside this className loose their padding, here we need the padding for those nodes
            >
                <div className="row floatAdvancetitleBox agreementDateHeaderBox align-items-center no-margin">
                    <div className="col-md-4"><h4 className='no-margin headerTitle'>{this.props.title ? this.props.title : ""}</h4></div>
                    {check_priv(this.props.entity, "edit") &&
                      <>
                        <div className={`col-md-1 ${this.state.edit_button_style}`} >
                            <Edit entity={this.props.entity} onClick={this.handleEdit}/>
                          {/* <input type="button" onClick={this.handleEdit} value="Edit"/> */}
                        </div>

                        {/* <div className = "row justify-content-center" style={{backgroundColor :"white"}}>
                        <div className="col-md-12" style={{backgroundColor :"rgba(255, 0, 0, .4)" }}> 
                              
                              <p style = {{color : "#01062c",fontSize : "12px",margin: "0 auto",padding :"5px"}} class=" text-center"> NOTE : Re-KYC process is required to update customer address</p>
                          </div>
                       </div> */}
                <div className={`col-md-1 ${this.state.save_button_style}`}>
                    <input  onClick={this.handleSave} type="button" className="btn btn-success btn-std" value="Save"/>
                </div>
                      </>
                  }

                </div>
                
                <div className={this.state.div_style}>
                  <div className="row no-margin labelTopgap align-items-end">
                    <div className="col-md-3">
                      <span className='viewCus_label'>Country</span><font className="text-danger">   *  </font>
                      {this.props.screen ?
                        <div className={this.state.borrower_country}>
                        <input type="text" id="country_code" value={this.country_code} className="form-control" />
                        </div>
                      :
                      <select id="country_code" value={this.props.data.country_code}  onChange={this.handleCountryChange.bind(this)} required="required" className="form-control disable_div">
                        {this.state.country_dd || LOADING_OPT}
                      </select>
                      }
                    </div>
                  </div>
                   {this.createUI(this.state.gps_value)} 
                   {this.state.loading_addr}
                </div>                
                 
            </div>
    );
  }
}


export default AddressInfo;