import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Option, TableauProps } from "..";
import { REQUIRED } from "../../../consts";
import { RhfOptionsTypeAndKeyCheckProperties } from "../../../helpers/helper";
import { RhfOptionType } from "../../../type";
import FormProvider from "../../CommonComponent/rhfComponents/formProvider";
import RHFAutocomplete from "../../CommonComponent/rhfComponents/rhfAutoComplete";
import TableauSection from "./tableauSection";
import CustomFlag from "../../common/component/CustomFlag";
import {
  BodyVarientOneTypo,
  BodyVarientTwoTypo,
  HeaderVarientH4Typo,
} from "../../CommonComponent/typography";

const FormSchema = Yup.object().shape({
  active_tableau: Yup.mixed<Option>().required(REQUIRED),
  active_country: Yup.mixed<RhfOptionType>().required(REQUIRED),
});

const FormSection = ({ tableauRes }: { tableauRes: TableauProps }) => {
  // default values
  const defaultValues = {
    active_tableau: tableauRes.options[0],
    active_country: tableauRes.country_codes[0],
  };

  // state
  const [tableauOptions, settableauOptions] = useState(tableauRes.options);

  // const
  const countryOptions = tableauRes.country_codes;

  // hook
  const theme = useTheme();

  // hookForm
  const methods = useForm({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  // rhf returns
  const { handleSubmit, watch, setValue } = methods;

  const activeCountry = watch("active_country");
  const activeTableau = watch("active_tableau");

  useEffect(() => {
    if (activeCountry) {
      const filterdTableausForMarket = tableauRes.options.filter(
        (tR) => tR.country_code === activeCountry.id
      );
      settableauOptions(filterdTableausForMarket);
      setValue("active_tableau", filterdTableausForMarket[0]);
    }
  }, [activeCountry]);

  // handler
  const onSubmit = handleSubmit((formData) => {});

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container gap={"15px"} display="flex" alignItems="center">
        <Grid item xs={6} md={3}>
          <RHFAutocomplete
            options={tableauOptions}
            label="Tableau"
            name="active_tableau"
            {...RhfOptionsTypeAndKeyCheckProperties}
            disableClearable
          />
        </Grid>
        <Grid item xs={6} md={3}>
          <RHFAutocomplete
            options={countryOptions}
            label="Country"
            name="active_country"
            {...RhfOptionsTypeAndKeyCheckProperties}
            disableClearable
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              return (
                <Box
                  key={key}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...optionProps}
                >
                  <CustomFlag
                    country_code={option?.id}
                    height={30}
                    width={30}
                  />
                  {option?.name}
                </Box>
              );
            }}
          />
        </Grid>
        <Grid item xs={6} md={3} display="flex" alignItems="center">
          <HeaderVarientH4Typo
            text={"Last Refreshed On "}
            overRideStyles={{
              color: theme.palette.text.primary,
              fontWeight: 500,
            }}
          />
          <BodyVarientTwoTypo
            text={tableauRes.tableaus[activeTableau?.name]?.last_refreshed_at}
            overRideStyles={{ color: theme.palette.text.primary, pl: "5px" }}
          />
        </Grid>
      </Grid>
      <TableauSection tableauRes={tableauRes} />
    </FormProvider>
  );
};

export default FormSection;
