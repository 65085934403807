import { createContext, Dispatch, MutableRefObject } from "react";

// state
export type AlertState = {
  showModal: boolean;
  msg: string;
  forTable: string;
};

export type AlertTriggerKeysProp = "OPEN" | "CLOSE";

// dispatch handler
export type TriggerModalHandler = {
  type: typeof TRIGGER_MODAL;
  payload: {
    show: boolean;
    msg: string;
    forTable: string;
  };
};

// dispatch type
export const TRIGGER_MODAL = "TRIGGER_MODAL";

// ctx type
type HmeAlertCtxProps = {
  hmeAlrtState: AlertState;
  dispatch: Dispatch<DispatchProps>;
  bcRef: MutableRefObject<BroadcastChannel | null>;
  methods: {
    openAlert: (props: Omit<AlertState, "showModal">) => void;
    clearAlert: ({forTable}:{forTable: string}) => void;
    triggerChannel: (props: {
      key: AlertTriggerKeysProp;
      msg: string;
      forTable: string;
    }) => void;
  };
};

export type DispatchProps = TriggerModalHandler;

export const HmeAlrtCtx = createContext({} as HmeAlertCtxProps);
