import React from "react";
import { AdminMenu } from "./AdminMenu";
import { Link } from "react-router-dom";
import Logo from "../common/partials/logo";
// import Flag from "react-flags";
import "./../../styles/flow.css";
import HomeLinks from "./HomeLinks";
import AppSupportDashBoard from "./AppSupportDashBoard";

import LoanPendingApproval from "../common/component/core/LoanPendingApproval";
import ListPaymentProof from "../home/ListPaymentProof";
import FlowComponent from "../common/component/core/FlowComponent";
// import {CustomerRoutes, FLOWRelMgrRoutes, DPRelMgrRoutes, LoanApplRoutes,LoanRoutes,KYCRoutes,PersonRoutes} from "./../../routes/user_routes";

// import {MarketRoutes, LenderRoutes, DataPrvdrRoutes, AccPrvdrRoutes,ProductRoutes, MasterDataRoutes, AgreementRoutes, ScoreRoutes,Reports,AccountRoutes,FieldvisitRoutes,CallLogRoutes, LeadRoutes, ManagementPrintRoute, PreApprovalRoutes, ManagementRoute,MonthlyReportPrintRoute, AssignRoute,SmsLogRoutes,CSRostersRoutes,BizOperationsReportPrintRoute, ActiveCustWoFARoutes,AgreementRenewalRoutes,InboundcallRoute, SwitchSMSVendorRoutes, settlement,ChrunManagementRoutes,SchedulerList, NotificationsRoute, OtpRoutes, TaskRequest } from "./../../routes/admin_routes";

import { list_lender_txn_stmts } from "../../actions/lender_actions";
// import Route from 'react-router-dom/Route';
import { get, check_priv, Config } from "./../../helpers/storage_helper";
import { FaCheckCircle, FaRegTimesCircle } from "react-icons/fa";
import { GiTakeMyMoney } from "react-icons/gi";
import { RiUserSearchFill } from "react-icons/ri";
import { GiArchiveResearch } from "react-icons/gi";
import { getRefAccounts } from "../../actions/account_txn_actions";
import $ from "jquery";
import ExpiredAgreement from "./ExpiredAgreement";
import OnHoldList from "./OnHoldList";
import KycPendingList from "./KycPendingList";
import OngoingRecoveryList from "./OngoingRecoveryList";
import DisplayAccStmtList from "./DisplayAccStmtList";
import SelectMarket from "./SelectMarket";
import DisplayList from "./DisplayList";
import WriteOffList from "./WriteOffList";
import ManagementDashboard from "../reports/container/management_dashboard";
// import history from '../../history';
import TaskRequests from "./TaskRequests";
import PendingRMAllocList from "./PendingRMAllocList";
import RMReport from "./RMReport";
// import { listAccountProvider } from '../../../actions/account_provider_actions';
import { listAccountProvider } from "../../actions/account_provider_actions";
import ForceCheckIn from "./ForceCheckIn";
import { lbl_amt } from "../../helpers/product_helper";
import { Homeacc_prvdrLogoselector } from "../../helpers/common_helper";
import { ProductUpgradeRoute } from "./../../routes/admin_routes";
import CSCallStatus from "./CSCallStatus";
import SelfRegistration from ".././lead/component/SelfRegistration";
import Schedulercomponent from "../Scheduler/Schedulercomponent";
import Softphone from "../cs_management/container/cs_soft_phone";
import { FaPhoneAlt, FaPhoneSlash } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import FreshChat from "../utility/Component/FreshChat";
import FoundersHome from "../reports/container/founders_home";

import NewAccountVerification from "../lead/component/NewAccountVerification";

//import ReactSignature from 'react-signature';
import { FaRegBell } from "@react-icons/all-files/fa/FaRegBell";
import Notification from "../Notification/Notification";
import { AiOutlineClose } from "react-icons/ai";
import { onMessage } from "firebase/messaging";
import { messaging } from "../../firebase/config";
import {
  GetLatestNotifications,
  UpdateReadStatus,
  updateFcmToken,
} from "../../actions/notification_action";
import NotificationModal from "../utility/Component/NotificationModal";
import SwitchPaymentProof from "./SwitchPaymentProof";
import PendingCashbacks from "./PendingCashbacks";
import PendingWithCustomer from "./PendingWithCustomer";
import { BiSolidLock } from "react-icons/bi";
import withRouter from "../../Router/Withrouter";
import DuplicateTxns from "./DuplicateTxns";
import FAUpgradedCustomers from "./FAUpgradedCustomers";

class AdminHome extends FlowComponent {
  state = {
    toSelectMarket: false,
    acc_prvdr: "",
    data_list: "",
    server_time: {},
  };
  constructor(props) {
    super(props);
    this.load_home_accounts = this.load_home_accounts.bind(this);
  }

  async componentWillMount() {
    var request = {
      country_code: get("market").country_code,
      time_zone: get("market").time_zone,
      status: "",
    };
    listAccountProvider({
      ...request,
      status: "enabled",
      biz_account: true,
    }).then((response) => {
      if (!response) {
        return;
      }
      if (response.status === "success") {
        let acc_prvdrs = [];
        let acc_provider_logos = {};
        response.data.list.map((each) => acc_prvdrs.push(each.acc_prvdr_code));
        response.data.list.map(
          (each) =>
            (acc_provider_logos[each.acc_prvdr_code] = each.acc_provider_logo)
        );
        this.setState({ acc_prvdr: acc_prvdrs });
        localStorage.setItem(
          "acc_prvdr_logos",
          JSON.stringify(acc_provider_logos)
        );
      }
    });

    // const logout = await super.touch_async()
    // this.setState({logout})
    this.load_home_accounts();

    this.setState({ window_path: window.location.pathname });

    setInterval(
      () => this.setState({ refresh_count: this.state.refresh_count + 1 }),
      60000
    ); // Pending Disbursal
    setInterval(
      () =>
        this.setState({
          pending_w_rm_refresh_count: this.state.refresh_count + 1,
        }),
      180000
    ); // Pending w/ RM
    setInterval(
      () =>
        this.setState({
          pending_w_cust_refresh_count: this.state.refresh_count + 1,
        }),
      120000
    ); // Pending w/ Customer Confirmation
    setInterval(
      () =>
        this.setState({
          switch_payment_proof_refresh_count: this.state.refresh_count + 1,
        }),
      120000
    ); // Switch payment proof approve screen
  }
  timeDiff = (endtime) => {
    const d = new Date(this.state.server_time);
    const d2 = new Date(endtime);

    const diff = Math.round((d.getTime() - d2.getTime()) / (1000 * 60));
    let m = "min ago";
    if (diff > 1) {
      m = "mins ago";
    }
    return diff + " " + m;
  };
  status_handler = (status, time = null) => {
    // const icon =`<h3> <FaUserPlus className="icon"/></h3>`;
    if (status == "imported") {
      return (
        <p className="no-margin p-0">
          {" "}
          <FaCheckCircle className="icon icon-tick" />
        </p>
      );
    } else if (status === "locked") {
      const d = new Date(this.state.server_time);
      const d2 = new Date(time);

      const diff = Math.round((d.getTime() - d2.getTime()) / (1000 * 60));

      const lockColor = diff < 240 ? "#ffcb00" : "";

      return (
        <p className="no-margin p-0 locked-text">
          <BiSolidLock
            className="icon icon-wrong"
            style={{ color: lockColor }}
          />
          <span className="locked-content">This account has been locked</span>
        </p>
      );
    } else {
      return (
        <p className="no-margin p-0">
          {" "}
          <FaRegTimesCircle className="icon icon-wrong" />
        </p>
      );
    }
  };
  load_home_accounts = () => {
    if (window.location.pathname !== "/") {
      return;
    }
  };
  state = {
    refresh_count: 0,
    cust_data: {
      cust_name: "Lydia Martin",
      mobile_num: "98654323234",
    },
    isIncomingCall: false,
    notification: {
      un_read_notifications_count: 0,
      show_notification: false,
      notification_arr: [],
      no_notification: false,
      loader: false,
      portfolio_modal: false,
      portfolio_details: null,
    },

    //logout : super.touch_async()
  };

  /*   touch(){
     var logout = false;
       
      touch({country_code : 'UGA'})
               .then((response) => {
                     if(!response){return };
                     
                     if(response.status === "success1"){
                     }else{
                        userService.logout();
                        logout = true;
                        alert("Session Expired")
                        //window.location.reload('/login')
                     }
                  }
                ); 
                
    return logout;
  }*/
  handleIncomingCall = (custDetails, isIncomingCall) => {
    // Set customer details for the incoming call
    this.setState({
      ...this.state,
      custDetails,
      isIncomingCall,
    });
    localStorage.setItem("isIncomingCall", JSON.stringify(true));
  };

  setIsCallAccepted = () => {
    let channel = new BroadcastChannel("webrtc");
    channel.postMessage({ message: "isCallAccepted" });
  };

  setIsCallRejected = () => {
    let channel = new BroadcastChannel("webrtc");
    channel.postMessage({ message: "isCallRejected" });
  };

  selectAccPrvdr = (event) => {
    if (
      this.roles === "super_admin" ||
      this.roles === "it_admin" ||
      this.roles === "ops_analyst"
    ) {
      this.toChange = "acc_prvdr";
      // history.push('/management-dashboard')
      this.props.navigate("/management-dashboard");
    } else {
      this.toChange = "acc_prvdr_only";
    }

    this.setState({ toSelectMarket: true });
  };

  selectMarket = (event) => {
    if (
      get("role_codes") === "super_admin" ||
      get("role_codes") === "it_admin" ||
      this.roles === "ops_analyst" ||
      (Config("global_user") && Config("global_user").includes(get("user_id")))
    ) {
      this.toChange = "market";
      this.setState({ toSelectMarket: true });
    }
  };

  // handle_visibility_change = ()=>{
  //   if(document.visibilityState === 'visible'){
  //     this.getNotifications()
  //   }
  // }
  // handle_message = (payload)=>{
  //   this.getNotifications()
  // }

  change_read = (notification) => {
    if (notification.read == false) {
      this.setState({
        notification: { ...this.state.notification, loader: true },
      });

      UpdateReadStatus(this.req({ notification_id: [notification.id] })).then(
        (response) => {
          if (!response) {
            this.setState({
              notification: {
                ...this.state.notification,
                loader: false,
                no_notification: true,
              },
            });
            return;
          }
          if (response.status === "success") {
            this.getNotifications();
          }
        }
      );
    }
  };
  componentDidMount() {
    const channel = new BroadcastChannel("webrtc");
    channel.onmessage = (event) => {
      let message = event.data.message;
      if (message == "incomingCall") {
        let data = event.data.data;
        console.log(data);
        this.setState({
          ...this.state,
          cust_data: data,
          isIncomingCall: true,
        });
      }
      if (
        message == "isCallAccepted" ||
        message == "isCallRejected" ||
        message == "hangup"
      ) {
        this.setState({
          ...this.state,
          isIncomingCall: false,
        });
      }
    };

    // if(get('role_codes') == 'investor' && window.location.pathname == "/" ){
    //   // history.push('/management-dashboard')
    //   this.props.navigate('/management-dashboard')
    // }

    var account = {
      lender_code: this.lender_code,
      country_code: this.country_code,
      to_recon: true,
      status: "enabled",
    };
    getRefAccounts(this.req({ account })).then((response) => {
      if (!response) {
        return;
      }
      if (response.status === "success") {
        if (response.data != "") {
          this.setState({ server_time: response.server_time });
          this.setState({ account_list: response.data });
        }
      } else {
        alert(response.message + "\n" + JSON.stringify(response.data));
      }

      window.dispatchEvent(new Event("storage"));

      // // get the latest notifications if background notifications is arrived
      // document.addEventListener('visibilitychange', this.handle_visibility_change)

      // // get foreground notifications
      // onMessage(messaging, this.handle_message)
    });
  }

  componentWillUnmount() {
    document.removeEventListener(
      "visibilitychange",
      this.handle_visibility_change
    );
  }

  getNotifications = () => {
    GetLatestNotifications(this.req({ notification_count: 8 })).then(
      (response) => {
        this.setState({
          notification: { ...this.state.notification, loader: true },
        });
        if (!response) {
          this.setState({
            notification: {
              ...this.state.notification,
              loader: false,
              no_notification: true,
            },
          });
          return;
        }
        if (response.status == "success") {
          if (response.data.length == 0) {
            this.setState({
              notification: {
                ...this.state.notification,
                loader: false,
                no_notification: true,
              },
            });
            return;
          }
          this.setState({
            notification: {
              ...this.state.notification,
              loader: false,
              notification_arr: response.data,
              no_notification: false,
            },
          });

          let un_read = response.data.filter((val) => val.read == false);
          this.setState({
            notification: {
              ...this.state.notification,
              un_read_notifications_count: un_read.length,
            },
          });
        }
      }
    );
  };

  handleBellclick = (option) => {
    if (option == null) {
      option = !this.state.notification.show_notification;
    }
    this.setState({
      notification: {
        ...this.state.notification,
        show_notification: option,
      },
    });
  };

  handle_portfolio_modal = (option) => {
    if (
      this.state.notification.portfolio_details.type == "flagged_customers" &&
      !option
    ) {
      this.change_read(this.state.notification.portfolio_details);
    }
    this.setState({
      notification: {
        ...this.state.notification,
        portfolio_modal: option,
      },
    });
  };

  set_portfolio_details = (notification) => {
    this.setState(
      {
        notification: {
          ...this.state.notification,
          portfolio_details: notification,
        },
      },
      () => this.handle_portfolio_modal(true)
      // this.change_read(notification)
    );
  };

  componentDidUpdate() {
    let curr_path = window.location.pathname;
    if (
      this.state.window_path != curr_path &&
      this.state.notification.portfolio_modal
    ) {
      this.handle_portfolio_modal(false);
      this.setState({ window_path: curr_path });
    }
  }

  render() {
    //var ReactSignature = require('react-signature');
    //  console.log(window.location.pathname);
    if (this.state.toSelectMarket) {
      return (
        <SelectMarket
          toChange={this.toChange}
          action="change"
          onClose={() => this.setState({ toSelectMarket: false })}
        />
      );
    }
    if (this.state.logout) {
      //return <Navigate to='/login'  />
      return <></>;
    }
    const role_codes = get("role_codes");
    const admin_roles = [
      "super_admin",
      "ops_admin",
      "market_admin",
      "it_admin",
      "ops_analyst",
    ];
    return (
      <>
        {/* <div className='incoming_call_modal' >
           <Modal className='incoming_call_modal' show={this.state.isIncomingCall} onHide={()=>this.setState({isIncomingCall:false})} backdrop='static' keyboard={false}>
            <Modal.Header className='p-0'></Modal.Header>

          <Modal.Body className='py-2'>  
            <div className='row m-0 d-flex align-items-center'>

              <div className='col-3 call-icon-container call-reject ' onClick={this.setIsCallRejected} type="button">
                <div style={{marginRight: "45px"}}>
                  <div className="ripple-wave ripple-red">
                    <div className="ripple-wave-2 ripple-red-2">
                      <FaPhoneSlash size={20} className='call-modal-icon text-danger ' />
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-6 text-center p-0' >
                <div>
                  <h3 className="fs-5 p-0 m-0 text-center col-12">{this.state.cust_data && this.state.cust_data.cust_name === "unknown" ? "UNKNOWN" : this.state.cust_data.cust_name}</h3>
                  <h3 className="fs-6 m-0 p-0 text-center col-12">{this.state.cust_data && this.state.cust_data.mobile_num}</h3>
                </div>
              </div>

              <div className='col-3 call-icon-container' onClick={this.setIsCallAccepted} type="button" >
                <div>
                  <div className="ripple-wave ripple-green">
                    <div className="ripple-wave-2 ripple-green-2">
                      <FaPhoneAlt size={20} className='call-modal-icon text-success' />
                    </div>
                  </div>
                </div>
              </div>

            </div>    
       
          </Modal.Body>

          </Modal>
          </div> */}
        {/* portfolio modal */}
        <div>
          {window.location.pathname != "/report/par_health_report" &&
            window.location.pathname != "/view_all_notifications" &&
            this.state.notification.portfolio_modal && (
              <NotificationModal
                notification={this.state.notification}
                handle_modal={this.handle_portfolio_modal}
                handle_bell_click={this.handleBellclick}
                id="portfolio_modal"
              />
            )}
        </div>
        {window.location.pathname != "/cs_managements/soft_phone" ? (
          <div>
            {check_priv("mgmt", "live_dashboard") && (
              <>{/* {ManagementPrintRoute} */}</>
            )}
            {check_priv("mgmt", "live_dashboard") && (
              <>{/* {MonthlyReportPrintRoute} */}</>
            )}
            {check_priv("mgmt", "live_dashboard") && (
              <>{/* {BizOperationsReportPrintRoute} */}</>
            )}
            {window.location.pathname !== "/management-dashboard/print" &&
              window.location.pathname !== "/monthly-report/print" &&
              window.location.pathname !== "/business_operations/print" && (
                <div className="wrapper ">
                  <div className="pb-3">
                    {window.location.pathname == "/" &&
                    ((Config("founders_screen_roles") &&
                      Config("founders_screen_roles").includes(
                        get("role_codes")
                      )) ||
                      (Config("global_user") &&
                        Config("global_user").includes(get("user_id")))) ? (
                      <FoundersHome />
                    ) : window.location.pathname == "/" &&
                      get("role_codes") == "investor" ? (
                      <ManagementDashboard />
                    ) : (
                      <>
                        {window.location.pathname == "/" &&
                          check_priv("home", "quick_access") && (
                            <div className="row home-menu-container mb-5">
                              <HomeLinks />
                              {this.state.account_list &&
                                check_priv("wallet", "stmts") && (
                                  <div className="home-menu box col m-2 text-center align-items-center">
                                    <div className="card card-pricing holiday_height">
                                      <div className="card-body">
                                        <h3 className="card-title">
                                          Wallet Statements
                                        </h3>
                                        <div>
                                          {/* <img className='img-fluid' style={{width:'70px',height:'30px',margin:"10px  0px"}} src={`/img/${this.state.account_list[0].props.children._self.acc_prvdr_code}_logo_color.png`}></img> 	 */}
                                          <div className="wallet_statement_mobno align-items-center">
                                            <div className="row p-0 m-0 align-items-center">
                                              <input
                                                type="checkbox"
                                                className="col-1 p-o m-0 text-right "
                                                style={{ height: "11px" }}
                                                onChange={(e) =>
                                                  this.setState({
                                                    show_balance:
                                                      e.target.checked,
                                                  })
                                                }
                                              />
                                              <label className="col text-left p-0 m-0">
                                                Show Balance
                                              </label>
                                            </div>
                                            {this.state.account_list &&
                                              this.state.account_list.length >
                                                0 &&
                                              this.state.account_list.map(
                                                (account, i) => (
                                                  <div className="w-100 d-flex  p-0 m-0 my-2">
                                                    <Homeacc_prvdrLogoselector
                                                      className="col  p-0 m-0"
                                                      logotype={
                                                        account.acc_prvdr_code
                                                      }
                                                      from_stmt={true}
                                                    />
                                                    <Link
                                                      to={`/account/${account.id}/statement`}
                                                      className="col-sm-4 text-left py-0 pr-0 pl-1 m-0 acc_num_font"
                                                      target="blank"
                                                    >
                                                      {account.acc_number}
                                                    </Link>
                                                    <p className="col small_font d-flex align-items-center no-padding no-margin  justify-content-center">
                                                      {this.state
                                                        .show_balance && (
                                                        <p
                                                          className="col small_font no-padding no-margin"
                                                          style={
                                                            account.lower_limit >=
                                                            account.balance
                                                              ? {
                                                                  color:
                                                                    "#ff0000",
                                                                }
                                                              : {
                                                                  color:
                                                                    "#dce0dd",
                                                                }
                                                          }
                                                        >
                                                          {lbl_amt(
                                                            account.balance,
                                                            this.currency_code
                                                          )}{" "}
                                                          -{" "}
                                                        </p>
                                                      )}
                                                      <p
                                                        className="col small_font no-padding no-margin "
                                                        style={{
                                                          color: "#dce0dd",
                                                        }}
                                                      >
                                                        {this.timeDiff(
                                                          this.state
                                                            .account_list[i]
                                                            .float_acc_stmt_end_time
                                                        )}
                                                      </p>
                                                      <p className="col-1 small_font no-padding no-margin">
                                                        {this.status_handler(
                                                          this.state
                                                            .account_list[i]
                                                            .status,
                                                          this.state
                                                            .account_list[i]
                                                            .float_acc_stmt_end_time
                                                        )}
                                                      </p>
                                                    </p>
                                                  </div>
                                                )
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                          )}

                        {window.location.pathname == "/" &&
                          check_priv("call_log", "search") && (
                            <div className="home_stalled_fas">
                              <CSCallStatus list_name="cs_call_status" />
                            </div>
                          )}
                        {window.location.pathname == "/" &&
                          check_priv("stmt_imports", "search") && (
                            <div className="">
                              <AppSupportDashBoard refresh={600000} />
                            </div>
                          )}
                        {window.location.pathname == "/" &&
                          check_priv("home", "scheduler_list_view") && (
                            <div className="">
                              <Schedulercomponent show="home" />
                            </div>
                          )}

                        {/*{window.location.pathname == "/" && check_priv('report','rm_report') &&
                      <div className="home_stalled_fas">
                        <RMReport/>
                      </div>
                    }*/}

                        {window.location.pathname == "/" &&
                          check_priv("lead", "kyc_list") && (
                            <div className="home_stalled_fa">
                              <DisplayList list_name="kyc_assigned_for_audit" />
                            </div>
                          )}
                        {window.location.pathname == "/" &&
                          check_priv("lead", "kyc_list") && (
                            <div className="home_stalled_fa">
                              <DisplayList list_name="kyc_in_progress" />
                            </div>
                          )}
                        {window.location.pathname == "/" &&
                          check_priv("lead", "kyc_list") && (
                            <div className="home_stalled_fa">
                              <DisplayList list_name="kyc_in_pending_with_audit" />
                            </div>
                          )}
                        {window.location.pathname == "/" &&
                          check_priv("lead", "kyc_pend_w_rm") && (
                            <div className="home_stalled_fa">
                              <DisplayList list_name="reassignment_pending_with_rm" />
                            </div>
                          )}

                        {window.location.pathname == "/" &&
                          check_priv("statement", "home_unknown_txns") && (
                            <div className="">
                              <DisplayAccStmtList list_name="unmatched_debits" />
                            </div>
                          )}
                        {window.location.pathname == "/" &&
                          check_priv("statement", "home_unknown_txns") && (
                            <div className="">
                              <DisplayAccStmtList list_name="unmatched_credits" />
                            </div>
                          )}
                        {window.location.pathname == "/" &&
                          check_priv("loan", "dup_txn_capture") && (
                            <div className="home_stalled_fa">
                              <DuplicateTxns />
                            </div>
                          )}
                        {window.location.pathname == "/" &&
                          check_priv("loan", "unreversed_dup_disb_list") && (
                            <div className="home_stalled_fa">
                              <DisplayList list_name="unreversed_duplicate_disbursals" />
                            </div>
                          )}
                        {window.location.pathname == "/" &&
                          check_priv("loan", "pending_confirmation_list") && (
                            <div className="home_stalled_fas">
                              {/* <PendingWithCustomer type ='pending_w_cust' /> */}
                              <DisplayList
                                list_name="pending_w_cust"
                                refresh={
                                  this.state.pending_w_cust_refresh_count
                                }
                              />
                            </div>
                          )}

                        {window.location.pathname == "/" &&
                          check_priv("loan", "unreversed_excess") && (
                            // && check_priv('','') &&
                            // }
                            <div className="home_stalled_fas">
                              <ForceCheckIn />
                            </div>
                          )}
                        {window.location.pathname == "/" &&
                          check_priv("lead", "pending_statement") && (
                            <div className="home_stalled_fa">
                              <DisplayList list_name="pending_statement" />
                            </div>
                          )}

                        {/* {window.location.pathname == "/" && check_priv("task","view_doc_request") &&
                      <div className="home_stalled_fas">
                        <TaskRequests task_type = 'view_doc_request' title = "Document View Request"/>
                      </div>
                    } */}

                        {/* {window.location.pathname == "/" &&  check_priv("customer","self_reg") &&
                      <>
                        <div className="home_stalled_fas">
                          <SelfRegistration />
                          </div>
                          <div className="home_stalled_fas">
                          <NewAccountVerification />
                        </div>
                      </>
                    } */}
                        {window.location.pathname == "/" &&
                          check_priv("loan", "waiver_requests") && (
                            <div className="home_stalled_fas">
                              <TaskRequests
                                task_type="waiver_request"
                                title="Pending Waiver Requests"
                              />
                            </div>
                          )}

                        {window.location.pathname == "/" &&
                          check_priv("switch", "list_payment_proof") 
                          && (Config("switch_supported_countries") 
                          && Config("switch_supported_countries").includes(this.country_code)) &&  (
                            <div className="home_stalled_fas">
                              <SwitchPaymentProof
                                refresh={
                                  this.state.switch_payment_proof_refresh_count
                                }
                              />
                            </div>
                          )}

                        {window.location.pathname == "/" &&
                          check_priv("sales", "pending_float_switch") && (
                            <>
                              <div className="home_stalled_fa">
                                <DisplayList list_name="pending_float_switch" />
                              </div>
                              <div className="home_stalled_fa">
                                <DisplayList list_name="unreversed_excess" />
                              </div>
                            </>
                          )}

                        {window.location.pathname == "/" &&
                          check_priv("list", "photo_payment_evidence") && (
                            <div className="home_stalled_fas">
                              <ListPaymentProof />
                            </div>
                          )}

                        {/* {window.location.pathname == "/" && check_priv('statement', 'home_unknown_txns') && 
                      <div className="" >
                        <DisplayAccStmtList list_name = 'review_pending_payments' refresh = {900000}/>
                      </div>
                    } 
                    
                     
                    {window.location.pathname == "/" && check_priv('loan','unreversed_excess') &&

                      <div className='home_stalled_fa'>
                          <DisplayList list_name='unreversed_excess'/>
                      </div>
                    }  

                     
                    {/* {window.location.pathname == "/" && check_priv('statement', 'home_unknown_txns') && 

                      <div className="" >
                        <DisplayAccStmtList list_name = 'review_pending_payments' refresh = {900000}/>
                      </div>

                    }*/}

                        {window.location.pathname == "/" &&
                        check_priv("application", "delayed_approval_list") &&
                        (get("role_codes") == "ops_admin" ||
                          get("role_codes") == "market_admin") ? (
                          <div className="home_stalled_fas">
                            <DisplayList
                              list_name="pending_w_rm"
                              isrefresh="false"
                              refresh={this.state.pending_w_rm_refresh_count}
                            />
                          </div>
                        ) : window.location.pathname == "/" &&
                          check_priv("application", "delayed_approval_list") ? (
                          <div className="home_stalled_fas">
                            <DisplayList
                              list_name="pending_w_rm"
                              isrefresh="true"
                              refresh={this.state.pending_w_rm_refresh_count}
                            />
                          </div>
                        ) : null}

                        {window.location.pathname == "/" &&
                          check_priv("loan", "hold_loan_list") && (
                            <div className="home_stalled_fas">
                              <OnHoldList refresh={this.state.refresh_count} />
                            </div>
                          )}
                        {window.location.pathname == "/" &&
                          check_priv("loan", "hold_loan_list") && (
                            <div className="home_stalled_fa">
                              <DisplayList list_name="pending_capture_disbursal" />
                            </div>
                          )}
                        {window.location.pathname == "/" &&
                          check_priv("cashbacks", "pending_cashbacks") && (
                            <div className="home_stalled_fas">
                              <PendingCashbacks
                                refresh={this.state.refresh_count}
                                type="pending_cashback"
                              />
                            </div>
                          )}
                        {window.location.pathname == "/" &&
                          check_priv("customer", "perform_kyc") && (
                            <div className="home_stalled_fas">
                              <KycPendingList />
                            </div>
                          )}

                        {window.location.pathname == "/" &&
                          check_priv("lead", "edit") && (
                            <div className="home_stalled_fas">
                              <PendingRMAllocList />
                            </div>
                          )}

                        {window.location.pathname == "/" &&
                          check_priv("loan", "upgrade_requests") && (
                            <div className="home_stalled_fas">
                              <TaskRequests
                                task_type="fa_upgrade_request"
                                title="Pending FA Upgrade Requests"
                              />
                            </div>
                          )}

                        {window.location.pathname == "/" &&
                          check_priv("loan", "late_auth_req") && (
                            <div className="home_stalled_fas">
                              <ListPaymentProof
                                task_type="fa_approval"
                                title="Late Payment - FA Approval "
                              />
                            </div>
                          )}

                        {/* {window.location.pathname == "/" && check_priv('rm','cust_app_access')&&
                      <div className='home_stalled_fa'>
                          <DisplayList list_name='cust_app_access'/>
                      </div>
                      }  */}
                        {window.location.pathname == "/" && check_priv("application","approval") && (
                          <div className="">
                            <LoanPendingApproval />
                          </div>
                        )}
                        {window.location.pathname == "/" &&
                          check_priv("loan", "fa_upgraded_customers") && (
                            <FAUpgradedCustomers />
                          )}

                        {/*{window.location.pathname == "/" && check_priv('customer','active_cust_wo_fa') &&
                      <div className='home_stalled_fa'>
                          <DisplayList list_name='active_cust_without_fa'/>
                      </div>
                    }

                    {window.location.pathname == "/" && check_priv('cust_agreement', 'expired_list') &&
                      <div className="">
                        <ExpiredAgreement/>
                      </div>
                    }

                    {{window.location.pathname == "/"  && check_priv('loan', 'search_list_view_write_off') &&
                      <div className="home_stalled_fas">
                        <WriteOffList/>
                      </div>
                    }
                  
                    {window.location.pathname == "/"  && check_priv('loan', 'recovery_list') &&
                      <div className="home_stalled_fas">
                        <OngoingRecoveryList/>
                      </div>
                    }*/}
                      </>
                    )}
                  </div>
                </div>
              )}
          </div>
        ) : (
          <>
            <Softphone
              handleIncomingCall={this.handleIncomingCall}
              callState={this.state}
              handleHangup={this.handleHangup}
              isCallAccepted={this.setIsCallAccepted}
            />
          </>
        )}
      </>
    );
  }
}

export default withRouter(AdminHome);
