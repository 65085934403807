// info msgs
export const ERROR_INFO = "An Error Occurred!";
export const SUSPENSE_INFO = "Please wait for a moment!";
export const FETCHING_INFO = "Fetching data please wait!";
export const FETCHING_SHORT_INFO = "Fetching...";
export const ERROR_SHORT_INFO = "Error occured!";
export const NO_DATA_FOUND_INFO = "No data found!";
export const SPLIT_TYPE_REQUIRED = "Split type is required";
export const DATE_REQUIRED = "Date is required";
export const START_DATE_REQUIRED = "Start date is required";
export const START_DATE_MIN_DATE_INFO =
  "Start date cannot be less than 01 Jun 2023";
export const START_DATE_WONT_FUTURE = "Start date cannot be future";
export const END_DATE_REQUIRED = "End date is required";
export const END_DATE_MIN_DATE_INFO =
  "End date cannot be less than 01 Jun 2023";
export const END_DATE_WONT_FUTURE = "End date cannot be future";
export const RM_REQUIRED = "RM is required";
export const AUD_REQUIRED = "Auditor is required";
export const START_DATE_GT_END_DATE = "Start date cannot be after end date";
export const END_DATE_LT_START_DATE = "End date cannot be before start date";
export const REQUIRED = "Required";
export const NO_MARKET_FOUND = 'No Market Found!'

// performance dashboard min date
export const MIN_DATE_FOR_AUDITOR_PERFORMANCE_DASHBOARD = new Date(
  "06/01/2023"
);

// quarter json
export const QUARTERS_IN_YEAR = [
  "Jan - Mar",
  "Apr - Jun",
  "Jul - Sep",
  "Oct - Dec",
];

// dd
export const DEFAULT_ACDD_OBJ = {
  name: "Choose",
  id: "choose",
};

// country code
export const RWANDA = "RWA";
export const UGANDA = "UGA"
export const MADAGASCAR = "MDG"
