import { dd_value } from "../../../../../actions/common_actions";
import useGetCountryListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetCountryListViaQuery";
import useGetDistrictListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetDistrictListViaQuery";
import useGetLocationViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetLocationViaQuery";
import useGetProvinceListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetProvinceListViaQuery";
import useGetSectorListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetSectorListViaQuery";
// import useGetTeritoryListViaQuery from "../../../../CommonComponent/commonAutoCompleteDD/queryHooks/useGetTeritoryListViaQuery";
import BorrowerCommonAddressEditUIRWA from "../BorrowerCommonAddressEditUIRwa";

/**
 *
 * @param param0 title the header of the view, prop to children
 * @param param1 addressObj the address record, prop to children
 * @param param2 entity used to check the user privileage to show the edit btn, prop to children
 * @param param3 custId need to send for the update api, prop to children
 * @param param4 addressViewFor the current res address and biz address have the location pick option, to show or hide the location field this field is used, prop to children
 * @returns form UI for the address record
 */
const BorrowerCommonAddressFormInitValGetContainerForRwa = ({
  title,
  addressObj,
  entity,
  custId,
  addressViewFor,
}: {
  title: string;
  addressObj: Record<string, string>;
  entity: string;
  custId: string;
  addressViewFor: "CUR_RES_ADD" | "PER_ADD" | "BIZ_ADD";
}) => {
  console.log(addressObj, "addressObj");
  // query hook
  const {
    countryList,
    isFetched: isClFetched,
    isError: isClError,
  } = useGetCountryListViaQuery();
  const {
    provinceList,
    isFetched: isPlFetched,
    isError: isPlError,
  } = useGetProvinceListViaQuery();
  const {
    sectorList,
    isFetched: isSlFetched,
    isError: isSlError,
  } = useGetSectorListViaQuery({
    parent_data_code: addressObj?.district,
  });
  const {
    districtList,
    isFetched: isDlFetched,
    isError: isDlError,
  } = useGetDistrictListViaQuery({
    parent_data_code: addressObj?.province,
  }); // district depends on region
  // const {
  //   territoryList,
  //   isFetched: isTlFetched,
  //   isError: isTlError,
  // } = useGetTeritoryListViaQuery();
  const {
    locationList,
    isFetched: isLlFetched,
    isError: isLlError,
  } = useGetLocationViaQuery();

  // for populate the mui fields we need {id: , name: } structure, but from api we are getting the id only for the res. fields, so that we are getting the obj from the dropdown cached list, *this query doesn't trigger the api call twice if we are calling the same api for autocomplete inside the form
  const countryVal = countryList?.data?.list?.find(
    (d: any) => d.id === addressObj?.country_code
  );
  const provinceVal = provinceList?.data?.list?.find(
    (d: any) => d.data_code === addressObj?.province
  );
  const districtVal = districtList?.data?.list?.find(
    (d: any) => d.data_code === addressObj?.district
  );
  const sectorVal = sectorList?.data?.list?.find(
    (d: any) => d.data_code === addressObj?.sector
  );
  // const territoryVal = territoryList?.data?.list?.find(
  //   (d: any) => d.data_code === addressObj?.territory
  // );
  const locationVal = locationList?.data?.list?.find(
    (d: any) => d.data_code === addressObj?.location
  );

  const isAllDataFetchedOrError =
    (isClFetched || isClError) &&
    (isPlFetched || isPlError) &&
    (isDlFetched || isDlError) &&
    (isSlFetched || isSlError) &&
    // (isTlFetched || isTlError) &&
    (isLlFetched || isLlError);

  // used for unnecessory rendering when the data is not available
  if (!isAllDataFetchedOrError) return <></>;

  return (
    <BorrowerCommonAddressEditUIRWA
      title={title}
      addressId={addressObj?.id}
      entity={entity}
      custId={custId}
      addressViewFor={addressViewFor}
      initialVal={{
        countryVal: {
          name: countryVal?.name ?? "Choose",
          id: countryVal?.id ?? "choose",
        },
        provinceVal: {
          name: provinceVal?.data_value ?? "Choose",
          id: provinceVal?.data_code ?? "choose",
        },
        districtVal: {
          name: districtVal?.data_value ?? "Choose",
          id: districtVal?.data_code ?? "choose",
        },
        sectorVal: {
          name: sectorVal?.data_value ?? "Choose",
          id: sectorVal?.data_code ?? "choose",
        },
        // territoryVal: {
        //   name: territoryVal?.data_value ?? "Choose",
        //   id: territoryVal?.data_code ?? "choose",
        // },
        locationVal: {
          name: locationVal?.data_value ?? "Choose",
          id: locationVal?.data_code ?? "choose",
        },
        cell: addressObj?.cell ?? "",
        village: addressObj?.village ?? "",
        landMark: addressObj?.landmark ?? "",
        gps: addressObj?.gps ?? "",
        verificationMethod:
          (dd_value(addressObj?.verification_method) as string) ?? "",
      }}
    />
  );
};

export default BorrowerCommonAddressFormInitValGetContainerForRwa;
