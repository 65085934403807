import React from "react";
import {
  viewBorrower,
  allowCustAppAccess,
  thirdparty_details,
} from "../../../actions/borrower_actions";
import FlowContainer from "../../common/container/core/FlowContainer";
import BorrowerPanel from "../component/BorrowerPanel";
import PersonPanel from "../../common/component/PersonPanel";
import AddressPanel from "../../common/component/AddressPanel";
import "../../../styles/flow.css";
import Toggle from "../../common/component/Toggle";
import { Alert } from "react-bootstrap";
import { getValueFromLangJson } from "../../../helpers/common_helper";
import withRouter from "../../../Router/Withrouter";
import { connect } from "react-redux";
import Softphone from "../../cs_management/container/cs_soft_phone";
import { setValuesIntoRedux } from "../../../Redux/Slices/CommonSlice";
import { get } from "../../../helpers/storage_helper";
import BusinessAddress from "../../common/component/BusinessAddress";
import { object } from "yup";
class ViewIndividualBorrowerContainer extends FlowContainer {
  state = {
    customer: {},
    owner_person: {},
    biz_address: {},
    thirdparty_details: "",
  };
  componentWillMount() {
    const cust_id = this.props.params.cust_id;
    this.props.setValuesIntoRedux({
      cust_id: cust_id,
      screen_from: "borrower_profile",
    });
    this.view_borrower();
    thirdparty_details(this.req({ cust_id: cust_id })).then((response) => {
      this.resp(response);
      if (!response) {
        return;
      }
      if (response.status == "success") {
        if (response.data != null) {
          this.setState({ thirdparty_details: response.data.third_party });
        } else {
          this.setState({ thirdparty_details: null });
        }
      }
    });
  }

  view_borrower() {
    this.cust_id = this.props.params.cust_id;
    viewBorrower(
      this.req({ cust_id: this.cust_id, screen: "view" }, true)
    ).then((response) => {
      this.resp(response);

      if (!response) {
        return;
      }

      this.owner_address = this.contact_persons = null;

      if (response.data.owner_address_id != response.data.biz_address_id) {
        if (response.data.owner_person) {
          this.owner_address = response.data.owner_person.owner_address;
        }
      }
      if (response.data.contact_persons) {
        this.contact_persons = response.data.contact_persons;
      }

      this.setState({
        customer: response.data,
        owner_person: response.data.owner_person,
        biz_address: response.data.biz_address,
        cust_app_access: response.data.cust_app_access,
        addl_mobile_num: response.data.addl_mob_num,
        already_referral_sms_send: response.data.already_referral_sms_send,
      });
    });
  }

  render() {
    return (
      <div className="container containerTopmargin max-width no-padding">
        <BorrowerPanel
          view_borrower={() => {
            this.view_borrower();
          }}
          customer={this.state.customer}
          address={this.state.biz_address}
          owner_person={this.state.owner_person}
          app_access={this.state.cust_app_access}
          calling_api={this.state.calling_api}
          already_referral_sms_send={this.state.already_referral_sms_send}
        />

        {this.state.owner_person && (
          <PersonPanel
            data={this.state.owner_person}
            addl_mob_num={this.state.addl_mobile_num}
            title="Business Owner Person"
          />
        )}
        {this.state.thirdparty_details && (
          <PersonPanel
            data={this.state.thirdparty_details}
            title="Third Party A/C Owner"
          />
        )}

        {
          this.state.biz_address &&
            Object.keys(this.state.biz_address).length > 0 && (
              <BusinessAddress address={this.state.customer} />
            )
          // <AddressPanel data={this.state.biz_address} title="Address Details" key="address_details" />
        }

        {this.owner_address ? (
          <div data={this.owner_address} title="Owner Address" />
        ) : (
          <div className="floatAdvanceperiodHeaderBox">
            <h2 className={"floatAdvanceperiodHeader padL_15 no-margin"}>
              {getValueFromLangJson("owner_address")} :{" "}
              {getValueFromLangJson("same_as_business_address")}
            </h2>
          </div>
        )}

        {this.contact_persons ? (
          this.contact_persons.map((contact_person) => (
            <>
              <PersonPanel data={contact_person} title="Handler" />
              <AddressPanel
                data={contact_person.contact_address}
                title="Handler Address"
              />
            </>
          ))
        ) : (
          <div className="floatAdvanceperiodHeaderBox">
            {console.log(getValueFromLangJson("handler"))}
            <h2 className={"floatAdvanceperiodHeader padL_15 no-margin"}>
              {getValueFromLangJson("handler")} :{" "}
              {getValueFromLangJson("same_as_owner_person")}
            </h2>
          </div>
        )}

        {this.props.softPhoneState.softPhone &&
          !this.props.softPhoneState.missed_calls_view &&
          !this.props.softPhoneState.isIncomingCall &&
          !this.props.softPhoneState.outgoingCall &&
          this.state.customer &&
          this.props.softPhoneState.show_nums &&
          get("market").country_code == "UGA" && (
            <div className="Call_log_section zoomIn_animation">
              <Softphone
                screen_from={"borrower_profile"}
                cust_id={
                  this.props.params.cust_id ? this.props.params.cust_id : null
                }
              />
            </div>
          )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    softPhoneState: state.cs_soft_phone,
  };
};

const mapDispatchToProps = {
  setValuesIntoRedux,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewIndividualBorrowerContainer)
);
