export function access_token() {
  // return authorization header with basic auth credentials
  if (localStorage.hasOwnProperty("access_token")) {
    return JSON.parse(localStorage.getItem("access_token"));
  }
}

export function get(key) {
  if (localStorage.hasOwnProperty(key)) {
    return JSON.parse(localStorage.getItem(key));
  }
}

export function get_item(key) {
  if (localStorage.hasOwnProperty(key)) {
    return localStorage.getItem(key);
  }
}

export function get_priv_obj() {
  var prv = get("privileges");
  var priv_obj = {};
  Object.keys(prv).map((key) => {
    const priv_tokens = prv[key].split(",");
    priv_obj[key] = {};
    priv_tokens.map((priv) => {
      priv_obj[key][priv] = true;
    });
  });
  return priv_obj;
}

export function lbl_view_btn(entity) {
  if (check_priv(entity, "edit")) {
    return "View / Edit";
  } else if (check_priv(entity, "list_view")) {
    return "View";
  }
}

export function check_priv(screen, priv_code) {
  var prv = get("privileges");
  var exists = false;
  if (prv) {
    Object.keys(prv).map((key) => {
      if (key == screen) {
        const priv_tokens = prv[key].split(",");
        priv_tokens.map((priv) => {
          if (priv_code == priv) {
            exists = true;
          }
        });
      }
    });
  }
  return exists;
}

export function set_logout() {
  localStorage.setItem("logout", "logout_" + Math.random());
}

window.addEventListener("storage", storageChange, false);

function storageChange(event) {
  if (event.key == "logout") {
    window.location.reload("/login");
  }
}
export function remove_all() {
  localStorage.removeItem("privileges");
  localStorage.removeItem("market");
  localStorage.removeItem("access_token");
  localStorage.removeItem("role_codes");
  localStorage.removeItem("market_list");
  localStorage.removeItem("user_id");
  localStorage.removeItem("user_name");
  localStorage.removeItem("app_version");
  localStorage.removeItem("master_data");
  localStorage.removeItem("master_data_version");
  localStorage.removeItem("acc_prvdr_code");
  localStorage.removeItem("acc_prvdr_logo");
  localStorage.removeItem("acc_prvdr_logos");
  localStorage.removeItem("fcmToken");
  // localStorage.removeItem('pendingRM');
  // localStorage.removeItem('pendingCUS');
  // localStorage.removeItem("faPendingdisb");
  localStorage.removeItem("jsonData");
  localStorage.removeItem("market_details");
  localStorage.removeItem("language_json");
  localStorage.removeItem("lender_code");

  if (get("at_token")) {
    localStorage.removeItem("at_token");
  }
  if (localStorage.getItem("client_tab_id")) {
    localStorage.removeItem("client_tab_id");
  }

  if (get("cs_container_tab_id")) {
    localStorage.removeItem("cs_container_tab_id");
  }
  if (sessionStorage.getItem("cs_container_tab_id")) {
    sessionStorage.removeItem("cs_container_tab_id");
  }
  if (sessionStorage.getItem("client_tab_id")) {
    sessionStorage.removeItem("client_tab_id");
  }
}

export function add_all(resp) {
  //localStorage.setItem('user', JSON.stringify(resp.data.user));
  localStorage.setItem("privileges", JSON.stringify(resp.data.user.privileges));
  localStorage.setItem("role_codes", JSON.stringify(resp.data.user.role_codes));
  localStorage.setItem("access_token", JSON.stringify(resp.data.access_token));
  localStorage.setItem("app_version", JSON.stringify(resp.data.app_version));
  localStorage.setItem("app_config", JSON.stringify(resp.data.app_config));
  localStorage.setItem(
    "kyc_docs_config",
    JSON.stringify(resp.data.kyc_docs_config)
  );
  localStorage.setItem(
    "language_json",
    JSON.stringify(resp.data.language_json)
  );
  localStorage.setItem("lender_code", JSON.stringify(resp.data.lender_code));

  sync_master_data(resp.data.master_data, resp.data.master_data_version);

  if (resp.data.user.market) {
    localStorage.setItem("market", JSON.stringify(resp.data.user.market));
  }

  if (resp.data.user.market_list) {
    localStorage.setItem(
      "market_list",
      JSON.stringify(resp.data.user.market_list)
    );
  }

  if (resp.data.user_id) {
    localStorage.setItem("user_id", JSON.stringify(resp.data.user_id));
  }
  if (resp.data.user_name) {
    localStorage.setItem("user_name", JSON.stringify(resp.data.user_name));
  }
}

export function set(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export function sync_master_data(
  db_master_data,
  db_master_data_version = null
) {
  if (
    !get("master_data") ||
    (db_master_data && get("master_data_version") != db_master_data_version)
  ) {
    localStorage.setItem("master_data", JSON.stringify(db_master_data));
    localStorage.setItem(
      "master_data_version",
      JSON.stringify(db_master_data_version)
    );
  }
}

export function Config($key) {
  if(get('app_config')){
    return get('app_config')[$key]
   }
  }