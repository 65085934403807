import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../../UI/CommonComponent/Header";
import AdminMenu from "../../UI/home/AdminMenu"
import { useDispatch, useSelector } from "react-redux";
import SelectMarket from "../../UI/home/SelectMarket";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { useEffect } from "react";
import MenuDrawer from "../../UI/home/MenuDrawer";
import CommonModal from "../../UI/common/component/CommonModal";
import { check_priv, get } from "../../helpers/storage_helper";
import CSSoftPhoneContainer from "../../UI/cs_management/container/CSSoftPhoneContainer";
import { setValuesIntoRedux } from "../../Redux/Slices/CommonSlice";
import FreshChat from "../../UI/utility/Component/FreshChat";
import { side_bar } from "../../styles/common_styles";
import { remove_bg_scroll } from "../../helpers/common_helper";





const HomeLayOut = ()=>{

    const datas = useSelector(state=>state.common_slice)


    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const { name_verification, configuration, market, is_drawer_open } = useSelector(state => state.HeaderSlice)
    const {is_modalOpened} = useSelector(state => state.cs_soft_phone)

    const role_codes = get('role_codes')
    const select_market = datas.admin_roles.includes(role_codes)
    const acc_prvdr_code = get('acc_prvdr_code')
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!localStorage.getItem('bacgroundColor')) {
            localStorage.setItem('backgroundColor', theme.palette.background.default)
            document.body.style.backgroundColor = theme.palette.background.default
        }

    }, [datas && datas.mode])

    useEffect(() => {
        dispatch(setValuesIntoRedux({ showMenus: false, show_nums: false, missed_calls_view: false }))
    }, [location.pathname])

    useEffect(() => {
        
    let  status = is_drawer_open? is_drawer_open :is_modalOpened
       remove_bg_scroll(status )
    }, [is_drawer_open , is_modalOpened]);

    const fixedHeader = {
        position: "fixed",
        top: '0',
        zIndex: is_drawer_open ? "1009" : 2000,
    }

    return (
        <>{get('access_token') && 
            <div>
                <Box>
                    <div>
                        <Box className='d-flex align-items-center justify-content-between' sx={(isMobile || isTablet) ? fixedHeader : ""}>
                            <Header />
                        </Box>
                        <div className="d-flex align-items-center justify-content-between">
                            <Box sx={(isMobile || isTablet) ? side_bar:""}>
                            <MenuDrawer />
                            </Box>
                            <div className="main-panel" style={{paddingTop:"80px"}}>
                                <div className=" content container-fluid outlet_content">
                                    <Outlet />
                                </div>
                            </div>
                        </div>
                        {(name_verification || configuration) &&
                            <CommonModal type={name_verification ? "name_verification" : configuration ? "configuration" : ""} />
                        }
                    </div>
                    {(get('market').country_code == 'UGA') ?
                        <div>{get('role_codes') && check_priv('home', 'chat_icon') && <CSSoftPhoneContainer />} </div> :
                        <>{get('role_codes') && check_priv('user', 'chat_support') && <FreshChat />}</>
                    }
                </Box>
            </div>
            }
        </>
    )
}


export default HomeLayOut;